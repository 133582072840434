import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './videos-list.scss';

@customElement(`${prefix}-videos-list`)
export class ComponentVideosList extends LitElement {
  public static styles = styles;

  public render() {
    return html`
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col class="flex-col" xs="24" lg="[20,2]">
            <slot></slot>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
    `;
  }
}
