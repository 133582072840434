import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './project-sections.scss';

@customElement(`${prefix}-project-sections`)
export class ProjectSections extends LitElement {
  public static styles = styles;
  private items: HTMLElement[];

  @property({type: String})
  private selectedImage = '';

  connectedCallback() {
    super.connectedCallback();
    this.items = [];
  }

  firstUpdated() {
    this.items = Array.from(this.querySelectorAll('app-project-sections-item'));
    setTimeout(() => {
      if (this.items[0]) {
        (this.items[0] as any).expanded = true;
      }
    }, 1000)
  }

  private handleItemSelect(event: CustomEvent) {
    this.items.forEach((item: any) => {
      item.expanded = item === event.target
    });
    this.selectedImage = event.detail.imageSrc;
  }

  public render() {
    return html`
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="24" lg="[9, 1]">
              <app-border-image src="${this.selectedImage}"></app-border-image>
          </djx-grid-col>
          <djx-grid-col xs="24" lg="[10, 2]">
            <div class="title">
              <slot name="title"></slot>
            </div>
            <slot @sectionselect="${this.handleItemSelect}"></slot>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
    `;
  }
}
