import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import scroller from '../../../scroller';
import prefix from '../prefix';
import styles from './index.scss';
import { elementPosition } from '../../../utils/element-position';

@customElement(`${prefix}-parallax-item`)
export class ParallaxItem extends LitElement {
  private elementPosition = [0, 0];
  private elementHeight = 0;
  private windowHeight = 0;
  public static styles = styles;

  @property({ type: Number })
  private offset = 0;

  connectedCallback() {
    super.connectedCallback();
    scroller.on('scroll', this.updateParallax);
    window.addEventListener('resize', this.updateElementPosition);
    setTimeout(() => {
      this.updateElementPosition();
      this.updateParallax();
    }, 500);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    scroller.off('scroll', this.updateParallax);
    window.removeEventListener('resize', this.updateElementPosition);
  }

  updateElementPosition = () => {
    this.elementPosition = elementPosition(this);
    const { height } = this.getBoundingClientRect();
    this.elementHeight = height;
    this.windowHeight = window.innerHeight;
  }

  updateParallax = () => {
    const { elementHeight, windowHeight: h } = this;
    const [x, y] = this.elementPosition;
    const top = y - scroller.scrollY;
    const offset = ((top + elementHeight / 2) / h - 0.5) * elementHeight * this.offset;
    this.style.transform = `translate3d(0, ${offset}px, 0)`;
  }

  public render() {
    return html`
      <slot></slot>
    `;
  }
}
