import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';

import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-area-projects`)
export class AreaProjects extends LitElement {
  public static styles = styles;

  public render() {
    return html`
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="24" lg="[23, 1]">
            <slot name="title"></slot>
          </djx-grid-col>
        </djx-grid-row>
        <djx-grid-row>
          <djx-grid-col xs="24" lg="[6, 2]">
            <djx-text size="sm" lh-lg>
              <slot></slot>
            </djx-text>
          </djx-grid-col>
        </djx-grid-row>
        <djx-grid-row>
          <djx-grid-col xs="24">
            <slot name="items"></slot>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
    `;
  }
}
