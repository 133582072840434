import { ViewportObserver, ViewportObservable } from '@smoovy/observer';
import Plyr from 'plyr';
import {LitElement, html} from 'lit';
import {property, customElement, state, query} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-video-player`)
export class VideoPlayer extends LitElement {
  public static styles = styles;
  private viewportObservable: ViewportObservable;
  private player: Plyr;

  @query("video")
  private nativeVideoElement: HTMLVideoElement;

  @query("#yt-player")
  private youTubeVideoElement: HTMLElement;

  @property({ type: Boolean })
  public autoplay = false;

  @property({ type: Boolean })
  public loop = false;

  @property({ type: String })
  public src = '';

  @property({ type: String })
  public poster = '';

  @property({ type: String })
  public type = '';

  @property({ type: Boolean })
  public trigger = 'intersect';

  @property({ type: Boolean})
  public lazyload = false;

  @state()
  private _playing = false;

  firstUpdated() {
    this.classList.remove('playing');
    this.classList.toggle('no-poster', this.poster === '');
    this.bind();
    if (this.autoplay) {
      this.playing = true;
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unbind();
  }

  async removeIframe() {
    const iframe = this.youtubeIframe;
    if (iframe) {
      this.youTubeVideoElement.removeChild(iframe);
    }
  }

  async bind() {
    if (this.isYoutube) {
      this.removeIframe();
      const iframe = document.createElement('iframe');
      iframe.src = `${this.src}?enablejsapi=1&autoplay=0&frameborder=0`;
      iframe.setAttribute('allowfullscreen','1');
      this.youTubeVideoElement.appendChild(iframe);
    } else {
      this.player = new Plyr(this.nativeVideoElement, {
        hideControls: true,
        controls: [],
        clickToPlay: false,
        autoplay: this.autoplay,
        loop: { active: this.loop },
        fullscreen: { enabled: false },
      });
    }
  }

  unbind() {
    if (this.viewportObservable) {
      this.viewportObservable.remove();
    }
  }

  play() {
    if (this.isYoutube) {
      this.toggleYoutubePlay(true);
    }
    if (this.player) {
      this.player.play();
    }
    this.playing = true;
  }

  toggleYoutubePlay(playing: boolean) {
    const { youtubeIframe: iframe } = this;
    if (iframe) {
      let url = iframe.src.replace('autoplay=1', '_auto_');
      url = url.replace('autoplay=0', '_auto_');
      url = url.replace('_auto_', `autoplay=${playing ? 1 : 0}`);
      this.youtubeIframe?.setAttribute('src', url);
    }
  }

  stop() {
    if (this.isYoutube) {
      this.toggleYoutubePlay(false);
    }
    if (this.player) {
      this.player.stop();
    }
    this.playing = false;
  }

  toggleButton(visible: boolean) {
    this.button?.classList.toggle('hidden', !visible);
  }

  introButton() {
    const button = this.button;
    if (button) {
      this.toggleButton(true);
      (button as any).transitionIn();
    }
  }

  get button() {
    return this.renderRoot.querySelector('app-link-button');
  }

  get playing() {
    return this._playing;
  }

  set playing(value: boolean) {
    this._playing = value;
    this.classList.toggle('playing', value);
  }

  get nativePlayerTemplate() {
    return html`
      <video
        @click="${this.stop}"
        data-poster="${this.poster}"
        plays-inline
      >
        <source src="${this.src}" type="${this.type}">
      </video>
    `
  }


  get youtubePlayerTemplate() {
    return html`
    <div class="yt-player" id="yt-player">
      <div
        class="yt-player__poster"
        style="background-image: url('${this.poster}')"
      >
      </div>
    </div>
    `;
  }

  get youtubeIframe() {
    return this.youTubeVideoElement.querySelector('iframe');
  }

  get isYoutube() {
    return /https:\/\/(www\.)?youtube\.com/.test(this.src);
  }

  get buttonTemplate() {
    return html`
      <app-link-button
        class="play-button"
        @click="${this.play}"
        trigger="${this.trigger}"
      >
        <a href="#">Video Abspielen</a>
      </app-link-button>
    `;
  }

  public render() {
    return html`
      ${this.isYoutube ? this.youtubePlayerTemplate : this.nativePlayerTemplate}
      ${this.poster ? this.buttonTemplate : ''}
    `;
  }
}
