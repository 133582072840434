
export const DEG2RAD = Math.PI / 180;
export const RAD2DEG = 180 / Math.PI;

export function lerp(a: number, b: number, t: number) {
  return (1 - t) * a + t * b;
}

export function clamp(val: number, min: number, max: number) {
  return Math.min(Math.max(min, val), max);
}

export function fixedDecimal(num: number, precision: number) {
  const n = Math.pow(10, precision);
  return Math.round(num * n) / n;
}

export function mod(current: number, limit: number) {
  return ( (current % limit) + limit ) % limit;
};

export function distance(a: number[], b: number[]) {
  return Math.hypot(a[0] - b[0], a[1] - b[1]);
}

export function toDegrees(radians: number) {
  return radians * RAD2DEG;
}

export function toRadians(degrees: number) {
  return degrees * DEG2RAD;
}

export function mapToRange(x: number, a1: number, a2: number, b1: number, b2: number) {
  return b1 + (x - a1) * (b2 - b1) / (a2 - a1);
}
