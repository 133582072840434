import { debounce } from '../utils/debounce';

export interface FiltereableItem extends HTMLElement {
  catid: string;
  slug: string;
  visible: boolean;
}

export interface FilterOptions {
  maxItemsPerPage: number;
  incrementSize: number;
  onAfterFilterUpdate: Function;
}

export class ItemsFilter {

  // All items
  private items: Set<FiltereableItem>;

  // All possible categories
  private categories: Set<string>;

  // Items after filtering original set
  private filteredItems: Set<FiltereableItem>;

  // Active categories filter
  private categoriesFilter: Set<string>;

  // Active slugs filter
  private slugFilter: Set<string>;

  // Visibility state of items, usefull to cache expensive calls to style attributes
  private visibleMap: Map<FiltereableItem, boolean>;

  // Instance options
  private options: FilterOptions;

  // Mmax limit of items to show
  private maxItems: number;

  constructor(options: FilterOptions) {
    this.options = options;
    this.items = new Set();
    this.visibleMap = new Map();
    this.filteredItems = new Set();
    this.slugFilter = new Set();
    this.categoriesFilter = new Set();
    this.categories = new Set();
    this.update = debounce(this.update, 50, false);
    this.resetMaxItems();
  }

  private toggleItem(item: FiltereableItem, visible: boolean) {
    const { visibleMap } = this;
    if (visibleMap.has(item) && visibleMap.get(item) !== visible) {
      visibleMap.set(item, visible);
      item.visible = visible;
    }
  }

  public registerItem(item: FiltereableItem) {
    this.items.add(item);
    this.visibleMap.set(item, true);
    this.toggleItem(item, false);
  }

  public registerCategory(category: string) {
    this.categories.add(category);
  }

  public resetMaxItems() {
    this.maxItems = this.options.maxItemsPerPage;
  }

  public incrementItemsFeed() {
    this.maxItems += this.options.incrementSize;
  }

  public update() {
    const { categoriesFilter, slugFilter, maxItems } = this;
    const filter = new Set(this.items);
    let visibleCount = 0;
    filter.forEach(item => {
      // Show the item by default
      let visible = true;

      // filter by category
      if (! categoriesFilter.has(item.catid)) {
        visible = false;
      }
      // filter by slug if any
      if (slugFilter.size > 0) {
        if (! slugFilter.has(item.slug)) {
          visible = false;
        }
      }

      // Hide if max items reached
      if(visibleCount > maxItems) {
        visible = false;
      }

      // Apply visibility to item
      if (!visible) {
        filter.delete(item);
      }
      visibleCount += Number(visible);
      this.toggleItem(item, visible);
    });

    this.filteredItems = filter;

    // Notify on next frame
    requestAnimationFrame(()=> {
      this.options.onAfterFilterUpdate();
    });
  }

  public clearSlugs() {
    this.slugFilter.clear();
  }

  public allCategories() {
    const { categories } = this;
    this.categoriesFilter = new Set(categories);
  }

  public clearCategories() {
    this.categoriesFilter.clear();
  }

  public addCategoryFilter(category: string) {
    this.categoriesFilter.add(category);
  }

  public addSlugFilter(slug: string) {
    this.slugFilter.add(slug);
  }
}