import { ViewportObserver, ViewportObservable } from '@smoovy/observer';

type TBreakpointRange = { min?: Number, max: Number};
const NOOP = () => {};

export default class ClassBreakpoints {
  private viewportObservable: ViewportObservable;
  private element: HTMLElement;
  private breakpoints: any;
  private callback: Function;

  constructor(element: HTMLElement, breakpoints = {}, callback: Function = NOOP) {
    this.element = element;
    this.breakpoints = breakpoints;
    this.callback = callback;
    this.bind();
  }

  async bind() {
    this.viewportObservable = ViewportObserver.changed((state) => {
      this.update(state);
    });
    this.update(await ViewportObserver.state);
  }

  private update(state: any) {
    const { element, breakpoints, callback } = this;
    for (let className in breakpoints) {
      const range = breakpoints[className] as TBreakpointRange;
      const min = range.min || 0;
      const max = range.max || Infinity;
      const visible = state.width >= min && state.width < max;
      element.classList.toggle(className, visible);
      if (this.callback && visible) {
        this.callback(className);
      }
    }
  }

  unbind() {
    if (this.viewportObservable) {
      this.viewportObservable.remove();
    }
  }
}