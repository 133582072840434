import {LitElement, html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';
import { TweenMax } from 'gsap';

@customElement(`${prefix}-link-button`)
export class LinkButton extends LitElement {
  public static styles = styles;

  @query('djx-text-transition')
  private textTransition: HTMLElement;

  @property({ type: String })
  private color = '';

  @property({ type: Boolean })
  private solid = false;

  @property({ type: Number })
  private delay = 0;

  @property({ type: String })
  private trigger = 'intersect';

  private handleClick(event: MouseEvent) {
    const routerLink = this.routerLink as any;
    if (routerLink.isExternal) {
      window.open(routerLink.url);
    }
    if (this.navigate()) {
      event.preventDefault();
    }
  }

  navigate() {
    const { routerLink } = this;
    if (routerLink && !(routerLink as any).isExternal) {
      (routerLink as any).navigate();
      return true;
    }
  }

  updated() {
    this.classList.toggle('solid', this.solid);
  }

  transitionIn() {
    setTimeout(() => {
      // @ts-ignore
      this.textTransition.transitionIn();
      this.classList.add('border-in');
    }, this.delay * 1000);
  }

  reset() {
    // @ts-ignore
    this.textTransition.reset();
    this.classList.remove('border-in');
  }

  get inlineStyle() {
    if (!this.color) return '';
    return html`
      <style>
        :host {
          background-color: ${this.color}
        }
      </style>
    `;
  }

  get routerLink() {
    return this.shadowRoot?.querySelector('djx-router-link');
  }

  public render() {
    return html`
      ${this.inlineStyle}
      <djx-intersect
        .enabled="${this.trigger === 'intersect'}"
        @enter="${this.transitionIn}"
      >
        <div class="container" @click="${this.handleClick}">
          <svg width="100%" height="100%">
            <rect id="path" pathLength="100" width="100%" height="100%"></rect>
          </svg>
          <djx-text-transition selector="a" mode="words" speed="0.8" trigger="manual">
            <djx-text size="sm" white uppercase>
              <djx-router-link>
                  <slot></slot>
              </djx-router-link>
            </djx-text>
          </djx-text-transition>
        </div>
      </djx-intersect>
    `;
  }
}
