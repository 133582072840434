import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './employee-contact-item.scss';

@customElement(`${prefix}-employee-contact-item`)
export class EmployeeContactItem extends LitElement {
  public static styles = styles;

  @property({ type: String })
  private color = '';

  public render() {
    const { color } = this;

    return html`
      <djx-text size="f16" .color="${color}" uppercase no-wrap class="prefix">
        <slot name="prefix"></slot>
      </djx-text>
      <djx-text
        size="f16"
        .color="${color}"
        uppercase
        underlineboldhover
        underline
        no-wrap
      >
        <slot></slot>
      </djx-text>
    `;
  }
}
