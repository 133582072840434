import {LitElement} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import icons from './icons';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-icon`)
export class Icon extends LitElement {
  public static styles = styles;

  @property()
  private name: string;

  public render() {
    return icons[this.name];
  }
}
