import { EventEmitter } from '@smoovy/event';
import { PointerSwiper } from './pointer-swiper';
import { lerp, mod, mapToRange, clamp } from '../utils/math';

interface SliderOptionParams {
  draggingClass?: string;
  infinite?: boolean;
}

interface SliderOptions {
  draggingClass: string;
  activeClass: string;
  infinite?: boolean;
  damping: number;
  inertia: number;
}

const DefaultOptions = {
  infinite: false,
  draggingClass: 'dragging',
  activeClass: 'active',
  inertia: 7,
  damping: 0.1,
}

export default class Slider extends EventEmitter {
  private swiper: PointerSwiper;
  private slides: HTMLElement[];
  private prototypes: HTMLElement[];
  private listenerMap: Map<HTMLElement, Function>;
  private container: HTMLElement;
  private current: HTMLElement;
  private options: SliderOptions;
  private inertia: number;
  private startTime: number;
  private offsetX: number;
  private prevX: number;
  private animate: boolean;
  private targetX: number;
  private currentX: number;
  private raf: number;
  private index: number;
  private absoluteIndex: number;
  private cancelNextClick: boolean;

  constructor(container: HTMLElement, options: SliderOptionParams) {
    super();
    this.options = { ...DefaultOptions, ...options };
    this.swiper = new PointerSwiper(container, {});
    this.container = container;
    this.targetX = 0;
    this.offsetX = 0;
    this.prevX = 0;
    this.currentX = 0;
    this.index = 0;
    this.slides = [];
    this.prototypes = [];
    this.inertia = 0;
    this.startTime = 0;
    this.animate = true;
    this.listenerMap = new Map();
    this.cancelNextClick = false;
    this.bindSwiperEvents();
    this.update();
  }

  private onSwiperStart = () => {
    this.inertia = 0;
    this.startTime = performance.now();
  }

  private onSwiperDrag = () => {
    this.container.classList.add('dragging');
    const [dx, _] = this.swiper.deltaPosition;
    this.animate = false;
    this.offsetX = dx;
    this.updateStyle();
  }

  public manualProgress(progress: number) {
    const { prototypes, options, current } = this;
    const { length } = this.prototypes;
    if (length === 0) return;
    const min = this.getDisplacementToCenter(this.prototypes[0]);
    const max = this.getDisplacementToCenter(this.prototypes[length - 1]);
    const centerX = mapToRange(progress, 0, 1, min, max);
    const index = Math.floor((progress * length));
    this.current = this.prototypes[index];
    this.targetX = centerX;
    this.updateSlideClasses();
  }

  private onSwiperEnd = () => {
    const { options } = this;
    this.container.classList.remove('dragging');
    if (!options.infinite && Math.abs(this.offsetX) > 200) {
      this.snapClosestSlide();
    }
    const deltaTime = performance.now() - this.startTime;
    this.inertia = (this.offsetX / deltaTime) * options.inertia;
    this.animate = true;
  }

  private onSwiperSwipe = (event: { direction: string }) => {
    const step = event.direction === 'left' ? 1 : -1;
    this.moveToIndex(this.index + step);
    this.cancelNextClick = true;
  }

  private onSlideClick(slide: HTMLElement) {
    if (!this.cancelNextClick && this.current !== slide) {
      const index = this.slides.indexOf(slide);
      this.moveToIndex(index);
    }
    this.cancelNextClick = false;
  }

  private bindSwiperEvents() {
    this.swiper.on('down', this.onSwiperStart);
    this.swiper.on('up', this.onSwiperEnd);
    this.swiper.on('drag', this.onSwiperDrag);
    this.swiper.on('swipe', this.onSwiperSwipe);
  }

  public unbind() {
    this.swiper.unbind();
    cancelAnimationFrame(this.raf);
  }

  private bindSlideEvents() {
    this.slides.forEach(slide => {
      const listener = this.onSlideClick.bind(this, slide);
      this.listenerMap.set(slide, listener);
      slide.addEventListener('click', listener as any);
    });
  }

  private unbindSlideEvents() {
    this.listenerMap.forEach((listener, slide) => {
      slide.removeEventListener('click', listener as any);
    });
    this.listenerMap.clear();
  }

  public registerSlide(slide: HTMLElement) {
    this.slides.push(slide);
    this.prototypes.push(slide);
    this.unbindSlideEvents();
    this.bindSlideEvents();
  }

  public moveTofirst() {
    if (this.prototypes.length > 0) {
      this.moveToIndex(this.prototypes.indexOf(this.prototypes[0]));
    }
  }

  private snapClosestSlide() {
    const { prototypes, container } = this;
    let closest = prototypes[0];
    let max = Infinity;
    const {width} = container.getBoundingClientRect();
    this.prototypes.forEach(slide => {
      const r = slide.getBoundingClientRect();
      let dst = Math.abs((r.left + r.width / 2) - width / 2);
      if (max > dst) {
        closest = slide;
        max = dst;
      }
    });
    this.moveToIndex(this.slides.indexOf(closest));
  }

  public moveToCurrent() {
    this.moveToIndex(this.slides.indexOf(this.current));
  }

  public next() {
    this.moveToIndex(this.index + 1);
  }

  public prev() {
    this.moveToIndex(this.index - 1);
  }

  public moveToIndex(index: number) {
    const { prototypes, slides } = this;
    if (this.options.infinite) {
      this.index = clamp(index, 0, slides.length);
      let slide = slides[this.index];
      if (!slide) {
        console.log('invalid slide', this.index);
        return;
      }
      const slideIndex = Number(slide.getAttribute('index'));
      if (!slide.previousElementSibling) {
        const last =  prototypes[mod(slideIndex - 1, prototypes.length)];
        const clone = last.cloneNode(true) as HTMLElement;
        slides.unshift(clone);
        slide.parentElement!.insertBefore(clone, slide);
        this.unbindSlideEvents();
        this.bindSlideEvents();
        this.offsetX -= slide.getBoundingClientRect().width;
      }
      if (!slide.nextElementSibling) {
        const start = prototypes[mod(slideIndex + 1, prototypes.length)];
        const clone = start.cloneNode(true) as HTMLElement;
        slides.push(clone);
        slide.parentElement!.appendChild(clone);
        this.unbindSlideEvents();
        this.bindSlideEvents();
      }
      this.moveTo(slide);
    } else {
      this.index = clamp(index, 0, prototypes.length);
      this.moveTo(prototypes[this.index]);
    }
  }

  private updateSlideClasses() {
    const { slides, options, current } = this;
    slides.forEach(slide => {
      const active = current === slide;
      slide.classList.toggle(options.activeClass, active);
    })
  }

  private getDisplacementToCenter(element: HTMLElement) {
    const { container } = this;
    const cRect = container.getBoundingClientRect();
    const eRect = element.getBoundingClientRect();
    // Limit displacement so elements at the sides dont leave gaps
    // See: https://codepen.io/desandro/pen/GgQREP
    //const maxDisplacement = container.scrollWidth - cRect.width;
    //centerX = clamp(centerX, -maxDisplacement, 0);
    return - eRect.left - eRect.width / 2 + cRect.left + cRect.width / 2;
  }

  public moveTo(element: HTMLElement) {
    if (! this.slides.includes(element)) return;

    // Calculate displacement for center position of element
    this.targetX = this.getDisplacementToCenter(element);

    // set current element
    const notify = this.current !== element;
    const prev = this.current;
    this.current = element;
    this.updateSlideClasses();
    if (notify) {
      this.emit('change', {
        index: this.index,
        currentElement: element,
        previousElement: prev
      });
    }
  }

  update = () => {
    const { damping } = this.options;
    if (this.raf) {
      cancelAnimationFrame(this.raf);
      this.raf = 0;
    }
    this.raf = requestAnimationFrame(this.update);
    if (!this.animate) return;
    this.inertia = lerp(this.inertia, 0, damping);
    this.offsetX = lerp(this.offsetX + this.inertia, 0, damping);
    this.currentX = lerp(this.currentX, this.targetX, damping);
    if (this.updateStyle()) {
      this.emit('update');
    }
  }

  public updateStyle() {
    const { container, currentX } = this;
    const x = currentX + this.offsetX;
    if (x !== this.prevX) {
      this.prevX = x;
      container.style.transform = `translateX(${x}px)`;
      return true;
    }
  }
}
