import {LitElement, html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './project-sections-item.scss';
import { TweenMax, gsap } from 'gsap';

@customElement(`${prefix}-project-sections-item`)
export class ProjectSectionsItem extends LitElement {
  public static styles = styles;

  @query('#content')
  private content: HTMLElement;

  @query('#description')
  private description: HTMLElement;

  @property({ type: Boolean })
  private expanded = false;

  @property({ type: String })
  private color = '';

  private get inlineStyle() {
    return html`
      <style>
        .title:after {
          color: ${this.color} !important;
        }
      </style>
    `;
  }

  private handleTitleClick() {
    const image = this.querySelector('djx-image');
    this.dispatchEvent(new CustomEvent('sectionselect', {
      bubbles: true,
      detail: {
        imageSrc: (image as any).src,
      }
    }));
  }

  updated() {
    this.classList.toggle('expanded', this.expanded);
    setTimeout(() => {
      this.updateVisibility();
    });
  }

  updateVisibility(animate = true) {
    const duration = animate ? 0.55 : 0;
    gsap.killTweensOf(this.content);
    if (this.expanded) {
      const { height } = this.description.getBoundingClientRect();
      TweenMax.to(this.content, duration, { height, ease: 'Quad.easeOut' });
    } else {
      TweenMax.to(this.content, duration, { height: 0, ease: 'Quad.easeOut' });
    }
  }

  onImageLoad() {
    setTimeout(() => {
      this.updateVisibility(false);
    });
  }

  public render() {
    return html`
      ${this.inlineStyle}
      <div class="title" @click="${this.handleTitleClick}">
        <djx-text
          size="mdd"
          weight="medium"
          color="${this.color}"
          lh-xs uppercase
          ?underlinebold="${this.expanded}"
          ?underlinedouble="${this.expanded}"
        >
          <slot name="section-title"></slot>
        </djx-text>
      </div>
      <div id="content" class="content">
        <div id="description">
          <slot name="section-image" @imageloaded="${this.onImageLoad}"></slot>
          <div class="content__description">
            <djx-text size="sm" class="wysiwyg-content">
              <slot></slot>
            </djx-text>
          </div>
        </div>
      </div>
    `;
  }
}
