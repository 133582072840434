import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import prefix from '../prefix';
import styles from './employee-card.scss';

@customElement(`${prefix}-employee-card`)
export class EmployeeCard extends LitElement {
  public static styles = styles;

  @property({ type: Boolean, reflect: true })
  private compact = false;

  @property({ type: String })
  private imagesrc = '';

  public render() {
    const { imagesrc } = this;
    return html`
      <div class="container">
        <djx-image cover full-size src="${imagesrc}"></djx-image>
        <div class="content">
          <djx-text size="f24" lh-md type="secondary" class="text-name">
            <slot name="name"></slot>
          </djx-text>
          <djx-text size="f16" lh-md class="text-description">
            <slot name="description"></slot>
          </djx-text>
          <div class="contact">
            <slot></slot>
          </div>
        </div>
      </div>
    `
  }
}
