import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-area-hero`)
export class AreaHero extends LitElement {
  public static styles = styles;

  @property({type: String})
  private overlaycolor = 'transparent';

  @property({ type: Number })
  private pagecount = 0;

  @property({ type: Number })
  private pagecurrent = 0;

  @property({type: String})
  private bgimage = '';

  get containerStyle() {
    return html`
      <style>
        .background:after { background-color: ${this.overlaycolor}; }
      </style>
    `;
  }

  public render() {
    const { bgimage, containerStyle } = this;
    return html`
      ${containerStyle}
      <div class="container">
      <djx-text-transition selector='h1' mode="words"  class="center">
        <djx-text xs="xxxl" md="xxl" weight="medium" lh-sm center>
          <slot name="title"></slot>
        </djx-text>
      </djx-text-transition>
      <div class="prev-button">
        <djx-router-link>
          <djx-text xs="smm" animation uppercase white weight="medium" center lh-sm>
            <slot name="prev-button"></slot>
          </djx-text>
        </djx-router-link>
      </div>
      <div class="next-button">
        <djx-router-link>
          <djx-text xs="smm" animation uppercase white weight="medium" center lh-sm>
            <slot name="next-button"></slot>
          </djx-text>
        </djx-router-link>
      </div>
      </div>
      <djx-image
        class="background"
        cover
        full-size
        zoom
        zoom-in
        src="${bgimage}"
      >
        <div class="pagination">
          <app-hero-pagination
            .count="${this.pagecount}"
            .current="${this.pagecurrent}"
          ></app-hero-pagination>
        </div>
      </djx-image>
    `;
  }
}
