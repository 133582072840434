import {LitElement, html, PropertyDeclaration} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import colStyles from './col.scss';
import rootStyles from './root.scss';
import rowStyles from './row.scss';
import prefix from '../prefix';

@customElement(`${prefix}-grid`)
export class Grid extends LitElement {
  public static styles = rootStyles;

  @property({ type: Boolean })
  private spacing: boolean = false;

  public render() {
    if (this.spacing) {
      this.classList.add('space-around');
    }

    return html`
      <slot></slot>
    `;
  }
}

@customElement(`${prefix}-grid-row`)
export class GridRow extends LitElement {
  public static styles = rowStyles;

  public render() {
    return html`<slot></slot>`;
  }
}

const breakpoints = [ 'xs', 'xss', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl' ];

@customElement(`${prefix}-grid-col`)
export class GridCol extends LitElement {
  public static styles = colStyles;

  public static get properties() {
    const props: { [name: string]: PropertyDeclaration } = {};

    breakpoints.forEach(bp => {
      // @ts-ignore
      props[bp] = { type: String, converter: (val: string) => {
        if (val.startsWith('[')) {
          return JSON.parse(val);
        } else {
          return parseInt(val, 10);
        }
      } };
    });

    return props;
  }

  public render() {
    const classes: string[] = [];

    breakpoints.forEach(bp => {
      const size = (this as any)[bp];

      if ( ! size) {
        return;
      }

      if (size instanceof Array) {
        classes.push(`col-${bp}-${size[0]}`);
        classes.push(`col-${bp}-offset-${size[1]}`);
      } else {
        classes.push(`col-${bp}-${size}`);
      }
    });

    this.classList.forEach(className => {
      if (className.startsWith('col-')) {
        this.classList.remove(className);
      }
    });

    this.classList.add(...classes);

    return html`<slot></slot>`;
  }
}
