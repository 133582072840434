
import { gsap, TweenMax } from 'gsap';
import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import { QueryDeep } from '../../../utils/query-deep';
import ClassBreakpoints from '../../../utils/class-breakpoints';
import prefix from '../prefix';
import styles from './menu-item.scss';

@customElement(`${prefix}-menu-item`)
export class MenuItem extends LitElement {
  public static styles = styles;
  private subitems: HTMLElement[] = [];
  private breakpoints: ClassBreakpoints;

  @property({type: Boolean})
  private active = false;

  @property({ type: Boolean })
  private color = '';

  public connectedCallback() {
    super.connectedCallback();
    this.breakpoints = new ClassBreakpoints(this, {
      'mobile': { max: 768 },
      'desktop': { min: 768 },
    }, () => this.updateChildrenVisibility());
  }

  public onMouseEnter() {
    const event = new CustomEvent('itemmouseenter', {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
    if (!this.isDesktop) return;
    this.active = true;
    if (this.subitems.length > 0) {
      TweenMax.fromTo(this.subitems, {
        opacity: 0,
        y: 15,
      }, {
        duration: 0.5,
        stagger: 0.1,
        opacity: 1,
        y: 0,
      });
    }
  }

  updateChildrenVisibility() {
    const opacity = Number(!this.isDesktop);
    if (this.subitems.length > 0) {
      gsap.killTweensOf(this.subitems);
      gsap.to(this.subitems, 0, { opacity });
    }
  }

  private onMouseLeave() {
    this.active = false;
    this.updateChildrenVisibility();
  }

  updated() {
    this.classList.toggle('active-menu-item', this.active);
    if (!this.active) {
      const children = this.shadowRoot!.querySelector('.children') as HTMLElement;
      this.subitems = QueryDeep(children, 'app-menu-item') as HTMLElement[];
      setTimeout(() => this.updateChildrenVisibility());
    }
  }

  get isDesktop() {
    return this.classList.contains('desktop');
  }

  public render() {
    return html`
      <div @mouseleave="${this.onMouseLeave}">
        <slot @mouseenter="${this.onMouseEnter}"></slot>
        <div class="children">
          <slot name="children"></slot>
        </div>
      </div>
    `;
  }
}
