import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-page-header`)
export class PageHeader extends LitElement {
  public static styles = styles;

  @property({type: String})
  private src = '';

  @property({type: Boolean})
  private framed = false;

  get image() {
    const { framed, src } = this;
    const framedClass = framed ? 'framed' : '';
    if (!src) return '';
    return html`
      <djx-transition preset="fade-in" selector="djx-image"  delay="1">
        <div class="background ${framedClass}">
            <djx-image cover full-size zoom-reverse class="background__image" src="${src}"></djx-image>
        </div>
      </djx-transition>
    `;
  }

  public render() {
    const { image } = this;
    return html`
      <div class="title">
        <slot></slot>
      </div>
      ${image}
    `;
  }
}
