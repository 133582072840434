import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-home-news`)
export class HomeNews extends LitElement {
  public static styles = styles;
  public render() {
    return html`
      <div>
        <slot name="title"></slot>
        <djx-spacer size="md"></djx-spacer>
        <slot name="items"></slot>
      </div>
    `;
  }
}
