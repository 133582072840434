import { TimelineMax, TweenMax } from 'gsap';
import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import { QueryDeep } from '../../../utils/query-deep';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-transition`)
export class DjxTransition extends LitElement {
  public static styles = styles;
  public done = false;

  @property({ type: Number })
  private delay = 0;

  @property({ type: Number })
  private stagger = 0;

  @property({ type: String })
  private preset = 'fade-in';

  @property({ type: String })
  private selector = '';

  private transitionIn = () => {
    if (this.done) return;
    this.done = true;
    const targets: any = QueryDeep(this, this.selector);
    const tl = new TimelineMax({ delay: this.delay });
    switch (this.preset) {
      case 'fade-in':
        tl.fromTo(targets, 1, { opacity: 0 }, {
          opacity: 1,
          stagger: this.stagger
        });
        break;
      case 'from-below':
      case 'from-above':
        tl.fromTo(targets, 1,
          {
            opacity: 0,
            y: this.preset === 'from-above' ? -40 : 40
          },
          {
            opacity: 1,
            y: 0,
            clearProps: 'all',
            stagger: this.stagger
          }
        );
        break;
      default:
        throw `Invalid preset name: ${this.preset}`;
    }
  }

  setTargetsOpacity(opacity: number) {
    const targets: any = QueryDeep(this, this.selector);
    if (targets.length > 0) {
      TweenMax.to(targets, 0, { opacity: opacity });
    }
  }

  updated() {
    if (this.done) return;
    this.setTargetsOpacity(0);
  }

  public render() {
    return html`
      <djx-intersect
        @enter="${this.transitionIn}"
      >
        <slot></slot>
      </djx-intersect>
    `;
  }
}
