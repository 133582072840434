import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import { Browser } from '@smoovy/utils';

import styles from './span.scss';
import prefix from '../prefix';

@customElement(`${prefix}-scroller-span`)
export class ScrollerSpan extends LitElement {

  public static styles = styles;

  public connectedCallback() {
    super.connectedCallback();

    if (Browser.client && !Browser.mobile) {
      this.calcBoundaries();
      this.calc();
    }
  }

  private calc() {
    setTimeout(() => {
      this.calcBoundaries();
      this.calc();
    }, 500);
  }

  public calcBoundaries() {
    const el = document.body.querySelector(`${prefix}-scroller`);
    const nav = document.body.querySelector(`app-nav`);
    if (el && nav) {
      this.style.height= `${el.clientHeight + nav.clientHeight}px`;
    }
  }
  public render() {
    return html`<slot></slot>`;
  }
}
