import {LitElement, html, PropertyDeclaration} from 'lit';
import {property, customElement, state, query} from 'lit/decorators.js';
// import { SplitText } from '../../../utils/split-text';
import { gsap } from 'gsap';
import { QueryDeep } from '../../../utils/query-deep';
import { SplitText } from 'gsap/SplitText';

import prefix from '../prefix';
import styles from './index.scss';

type TModifiers = {
  [key: string]: boolean
}

gsap.registerPlugin(SplitText);

enum TextSize {
  XXXS = 'xxxs',
  XXS = 'xxs',
  XS = 'xs',
  SM = 'sm',
  SMM = 'smm',
  MD = 'md',
  MDD = 'mdd',
  MDL = 'mdl',
  LG = 'lg',
  LGL = 'lgl',
  XL = 'xl',
  XXL = 'xxl',
  XXXL = 'xxxl',
  XXXXL = 'xxxxl',
}

const breakpoints = [ 'xs', 'xss', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl' ];
const flags = [
  'underline',
  'underlinehover',
  'underlinebold',
  'underlinedouble',
  'underlinegreen',
  'underlineboldhover',
  'uppercase',
  'green',
  'black',
  'gray',
  'white',
  'center',
  'right',
  'greenhover',
  'small',
  'no-wrap',
  'ls-xxs',
  'ls-xs',
  'ls-sm',
  'ls-md',
  'lh-xxs',
  'lh-xxxs',
  'lh-xs',
  'lh-sm',
  'lh-md',
  'lh-lg',
];

@customElement(`${prefix}-text`)
export class Text extends LitElement {
  public static styles = styles;
  private topSplit: any;
  private bottomSplit: any;

  @query('.text-top')
  textTop: HTMLElement;

  @query('.text-bottom')
  textBottom: HTMLElement;

  @property({ type: String })
  private color = "";

  @property()
  private size: TextSize;

  @property({ type: Boolean })
  private animation = false;

  @state()
  private classes: String[] = [];

  @property()
  private weight: string;

  @property()
  private type: string;

  get inlineStyle() {
    const { color } = this;
    return color ? html`
      <style>
        :host {
          --text-color: ${this.color};
          --text-underline-color: ${this.color};
        }
      </style>
    `: '';
  }

  public static get properties() {
    const props: { [name: string]: PropertyDeclaration } = {};
    breakpoints.forEach(bp => {
      props[bp] = { type: String };
    });
    flags.forEach(flag=> {
      props[flag] = { type: Boolean };
    });
    return props;
  }

  updated() {
    if (this.animation) {
      this.cloneTextNode();
      this.bindTextAnimation();
    }
  }

  private bindTextAnimation() {
    const top = QueryDeep(this.textTop, 'a');
    const bottom = this.textBottom.querySelector('a');
    const opts = { type: 'chars', charsClass: 'char' };
    this.topSplit = new SplitText(top, opts);
    this.bottomSplit = new SplitText(bottom, opts);
    gsap.to(this.bottomSplit.chars, { duration: 0, opacity : 0 });
  }

  transitionIn() {
    const tl = gsap.timeline();
    const tc = this.topSplit.chars;
    const bc = this.bottomSplit.chars;
    gsap.killTweensOf(tc);
    gsap.killTweensOf(bc);
    tl.set(bc, { opacity: 1 });
    for (let i = 0; i < tc.length; i++) {
      const tChar = tc[i];
      const bChar = bc[i];
      tl.fromTo(tChar, { y: 0 }, { duration: 0.35, y: -25, ease: 'Power3.out' }, i > 0 ? '-=0.325' : 0);
      tl.fromTo(bChar, { y: 25 }, { duration: 0.2, y: 0, ease: 'Power3.out' }, '<');
    }
    tl.timeScale(0.8);
  }

  cloneTextNode() {
    const top = QueryDeep(this.textTop, 'a');
    if (top.length > 0) {
      const node = top[0].cloneNode(true);
      this.textBottom.appendChild(node);
    }
  }

  get staticTextTemplate() {
    this.updateClasses();
    return html`
      ${this.inlineStyle}
      <div class="text ${this.classes.join(' ')}">
        <slot></slot>
        <div class="text__underline"></div>
      </div>
    `;
  }

  get animatedTextTemplate() {
    this.updateClasses();
    return html`
      <div class="container" @mouseenter="${this.transitionIn}">
        <div class="text text-bottom ${this.classes.join(' ')}">
          <div class="text__underline"></div>
        </div>
        <div class="text text-top ${this.classes.join(' ')}">
          <slot></slot>
        </div>
      </div>
    `;
  }

  updateClasses() {
    const classes = [];
    breakpoints.forEach(bp => {
      const size = (this as any)[bp];
      if ( !!size) {
        classes.push(`size-${bp}-${size}`);
      }
    });

    flags.forEach(flag => {
      const value = (this as any)[flag];
      if (value) {
        classes.push(flag);
      }
    })

    if (this.weight) {
      classes.push(`weight-${this.weight}`);
    }

    if (this.type) {
      classes.push(`type-${this.type}`);
    }

    if (this.size) {
      classes.push(`size-${this.size}`);
    }
    this.classes = classes;
  }

  public render() {
    return this.animation ? this.animatedTextTemplate : this.staticTextTemplate;
  }
}
