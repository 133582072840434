import WebFont from 'webfontloader';
import { waitFor } from '../scripts/utils/wait-for';

export default class Application {
  public constructor() {
    WebFont.load({
      timeout: 20000,
      custom: {
        families: ['gt-america', 'schnyder-wide-s'],
        urls: [ `${(window as any)._assetsURL}/dist/app.css` ],
      },
      active: () => {
        document.body.classList.add('fonts-ready');
      }
    });
    document.addEventListener('preloaderdone', async () => {
      document.body.classList.remove('loading');
      await waitFor(() => {
        return document.body.classList.contains('fonts-ready');
      }, 15000);
      document.dispatchEvent(new CustomEvent('loaded'));
    });

    // delay the signal for preloader to start a little bit so
    // all components have the chance to notify the image loadings
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('script-initialized'));
    }, 250);

  }

}