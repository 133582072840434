import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './team-staff-item.scss';

@customElement(`${prefix}-team-staff-item`)
export class TeamStaffItem extends LitElement {
  public static styles = styles;

  @property({ type: Boolean })
  private visible = true;

  @property({type: String, reflect: true})
  private catid = '';

  updated() {
    this.classList.toggle('visible', this.visible);
  }

  public connectedCallback() {
    super.connectedCallback();
    this.notifyRegister();
  }

  private notifyRegister() {
    requestAnimationFrame(() => {
      this.dispatchEvent(new CustomEvent('staffitemregister', {
        bubbles: true,
      }));
    });
  }

  public render() {
    return html`
    <djx-text size="lgl" weight="medium">
      <slot name="title"></slot>
    </djx-text>
    <div class="staff">
      <slot></slot>
    </div>
    `;
  }
}
