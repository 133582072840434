import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-hero-pagination`)
export class HeroPagination extends LitElement {
  public static styles = styles;

  @property({type: Number})
  private count = 0;

  @property({type: Number, reflect: true})
  private current = 0;

  get items() {
    const { count, current } = this;
    let result = [];
    for (let i = 0; i < count; i++) {
      const activeClass = i === current ? 'list__item--active' : '';
      result.push(html`
        <li data-index="${i}" class="list__item ${activeClass}"></li>
      `);
    }
    return result;
  }

  public render() {
    return html`
      <ul class="list">
        ${this.items}
      </ul>
    `
  }
}
