import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-footer`)
export class Footer extends LitElement {
  public static styles = styles;

  public render() {
    return html`
    <footer>

      <div class="current-area">
        <djx-grid>
          <djx-grid-row>
            <djx-grid-col xs='24' lg='4'>
              <djx-text size="sm" weight="medium" uppercase>
                <slot name='current-area-text'></slot>
              </djx-text>
              <djx-spacer size="xs"></djx-spacer>
            </djx-grid-col>
            <djx-grid-col xs='24' lg='[9, 1]'>
              <slot name="current-area"></slot>
            </djx-grid-col>
            <djx-grid-col xs='24' lg='[9, 1]'>
              <ul class="current-area__menu">
                <slot name="current-menu"></slot>
              </ul>
            </djx-grid-col>
          </djx-grid-row>
        </djx-grid>
      </div>

      <div class="other-areas">
        <djx-grid>
          <djx-grid-row>
            <djx-grid-col xs='24' lg='4'>
              <djx-text size="sm" weight="medium" lh-sm uppercase>
                Weitere Geschäftsbereiche
              </djx-text>
              <djx-spacer size="xs"></djx-spacer>
            </djx-grid-col>
            <djx-grid-col xs='24' lg='[9, 1]'>
              <slot name="left-area"></slot>
              <br />
            </djx-grid-col>
            <djx-grid-col xs='24' lg='[9, 1]'>
              <slot name="right-area"></slot>
              <br />
            </djx-grid-col>
          </djx-grid-row>
        </djx-grid>
      </div>

      <div class="legals">
        <djx-grid>
          <djx-spacer size="xs"></djx-spacer>
            <div class="wrapper">
              <div class="copyright">
                <slot></slot>
              </div>
              <div class="legals-links">
                <slot name="legals"></slot>
              </div>
            </div>
          <djx-spacer size="xs"></djx-spacer>
        </djx-grid>
      </div>
    </footer>
    `;
  }
}
