type CallbackParameter = () => any;
const Queue: Map<CallbackParameter, number> = new Map();

export function requestFrame(callback: CallbackParameter) {
  if (!Queue.has(callback)) {
    const handle = requestAnimationFrame(() => {
      Queue.delete(callback);
      callback();
    });
    Queue.set(callback, handle);
  }
}

export function cancelFrame(callback: CallbackParameter) {
  if (Queue.has(callback)) {
    const handle = Queue.get(callback)!;
    cancelAnimationFrame(handle);
    Queue.delete(callback);
  }
}
