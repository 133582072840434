import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-intersect`)
export class Intersect extends LitElement {
  public static styles = styles;
  private observer: IntersectionObserver;
  private intersecting = false;
  private binded = false;

  @property({type: Boolean})
  private enabled = true;

  @property({type: Boolean})
  private once = false;

  @property({type: Number})
  private threshold = 0;

  bindObserver = () => {
    if (!this.binded) {
      this.binded = true;
      const options = { threshold: this.threshold };
      this.observer = new IntersectionObserver(this.onObserverCallback, options);
      this.observer.observe(this);
    }
  }

  private onObserverCallback = (entries: any) => {
    if (this.enabled) {
      const isIntersecting = entries[0].isIntersecting;
      if (this.intersecting && !isIntersecting) {
        this.intersecting = false;
        this.dispatchEvent(new CustomEvent('leave', {
          bubbles: true,
          composed: true,
        }));
      }

      if (isIntersecting) {
        this.intersecting = true;
        if (this.once) {
          this.observer.unobserve(this);
        }
        this.dispatchEvent(new CustomEvent('enter', {
          bubbles: true,
          composed: true,
        }));
      }
    }
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('loaded', this.bindObserver);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.binded) {
      this.observer.unobserve(this);
      document.removeEventListener('loaded', this.bindObserver);
    }
    this.binded = false;
  }

  public render() {
    return html`
        <slot></slot>
    `;
  }
}
