import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-spinner`)
export class Spinner extends LitElement {
  public static styles = styles;

  @property({ type: Boolean })
  public visible = true;

  public render() {
    if(!this.visible) {
      return '';
    }
    return html`
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-95 64.7 73.7 128.3">
          <path pathLength="100" d="M-67.4 117.6v28.3c0 .5 0 1.4.2 2.6v7c0 2.8-.7 4.8-1.9 5.9-1.2 1.1-3.4 1.9-6.4 2.5l-11.3 2.2-.7-.7.4-17.8c.1-5 .2-9 .2-12.2v-23.8c0-3.6-.6-7.6-1.7-11.9v-1.2l14.9-17c3.1-3.6 6-5.5 8.9-5.8 1.5 0 2.7 0 3.7.2l23.4 1.9.5 1.4-7.5 11.5c-2 3.1-4.5 4.5-7.4 4.5-1.2 0-3.1-.2-6-.5l-9.3-1.2v16.7l14-7.9c5.2 0 9.6 1.2 13.1 3.7s6.4 6.1 8.6 11c2.1 4.3 3.3 8.9 3.7 13.6.2 1.7.2 3.4.2 5 0 10.1-2.5 19.1-7.5 27.1-5.4 8.4-12.6 14.1-21.7 17-3.8 1.2-8 1.8-12.8 1.8l-.7-1v-4.8c0-3.5 2.1-5.1 6-5.1h3.6c4.4 0 7.9-2.4 10.3-7.2 2-3.8 3.1-8.2 3.2-13.2.4-12.7-2.3-22-8.3-28.1-2.8-2.8-6.8-4.5-11.7-4.5z"/>
        </svg>
      </span>
    `;
  }
}
