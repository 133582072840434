import {LitElement, html} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import ClassBreakpoints from '../../../utils/class-breakpoints';

import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-project-location`)
export class ProjectLocation extends LitElement {
  public static styles = styles;

  private breakpoints: ClassBreakpoints;

  @state()
  private isDesktop= false;

  public connectedCallback() {
    super.connectedCallback();
    this.breakpoints = new ClassBreakpoints(this, {
      'mobile': { max: 1024 },
      'desktop': { min: 1024 },
    }, this.onBreakpointChange.bind(this));
  }

  private onBreakpointChange(name: string) {
    this.isDesktop = name === 'desktop';
  }

  public render() {
    const { isDesktop } = this;
    return html`
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="24">
            <div class="title">
              ${ !isDesktop ? html`
                <djx-text-transition selector="djx-text" mode="words">
                  <slot name="title"></slot>
                </djx-text-transition>
              `: '' }
            </div>
          </djx-grid-col>
        </djx-grid-row>
        <djx-grid-row>
          <djx-grid-col xs="24" lg="[9, 1]">
            <djx-text-transition selector="djx-text" mode="words">
              <slot name="title"></slot>
            </djx-text-transition>
            <div class="address">
              <slot name="address"></slot>
            </div>
            <div class="link">
              <djx-router-link>
                <slot name="link"></slot>
              </djx-router-link>
            </div>
          </djx-grid-col>
          <djx-grid-col xs="24" lg="[10,2]">
            <slot name="map-location"></slot>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
    `;
  }
}
