import {LitElement, html} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';
import ClassBreakpoints from '../../../utils/class-breakpoints';


@customElement(`${prefix}-about-content`)
export class AboutContent extends LitElement {
  public static styles = styles;
  private breakpoints: ClassBreakpoints;

  @state()
  private isDesktop: boolean;

  public connectedCallback() {
    super.connectedCallback();
    this.breakpoints = new ClassBreakpoints(this, {
      'desktop': { min: 1024 },
    }, () => {
      this.isDesktop = this.classList.contains('desktop');
    });
  }

  get mobileTemplate() {
    return html`
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="24" lg=[12,11]>
            <slot name="title"></slot>
          </djx-grid-col>
        </djx-grid-row>
        <djx-grid-row>
          <djx-grid-col xs="24">
            <slot name="content-0"></slot>
            <slot name="slider-first"></slot>
            <slot name="content-1"></slot>
            <slot name="slider-second"></slot>
            <slot name="content-2"></slot>
            <slot name="content-3"></slot>
            </div>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
    `;
  }
  get desktopTemplate() {
    return html`
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="24" lg=[12,11] class="title-col">
            <slot name="title"></slot>
          </djx-grid-col>
        </djx-grid-row>
        <djx-grid-row>
          <djx-grid-col xs="24" lg="10">
            <slot name="slider-first"></slot>
            <slot name="slider-second"></slot>
          </djx-grid-col>
          <djx-grid-col xs="24" lg="[8, 3]">
            <div class="content">
              <slot name="content-0"></slot>
              <slot name="content-1"></slot>
              <slot name="content-2"></slot>
              <slot name="content-3"></slot>
            </div>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
    `;
  }

  public render() {
    return this.isDesktop ? this.desktopTemplate :  this.mobileTemplate;
  }
}
