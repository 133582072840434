import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';

import prefix from '../prefix';
import styles from './area-menu-item.scss';

@customElement(`${prefix}-area-menu-item`)
export class AreaMenuItem extends LitElement {
  public static styles = styles;

  public render() {
    return html`
    <djx-router-link>
      <djx-text
        size="sm"
        ls-xs
        no-wrap
        uppercase
        underline
        underlineboldhover
      >
        <slot></slot>
      </djx-text>
    </djx-router-link>
    `;
  }
}
