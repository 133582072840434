import {LitElement, html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';

import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-area-featured-item`)
export class AreaFeaturedItem extends LitElement {
  public static styles = styles;

  @query('djx-router-link')
  private routerLink: HTMLElement;

  handleBackgroundClick() {
    const { routerLink } = this;
    if (routerLink && !(routerLink as any).isExternal) {
      (routerLink as any).navigate();
    }
  }

  handleMouseEnter() {
    // @ts-ignore
    this.linkButton!.transitionIn();
  }

  handleMouseLeave() {
    // @ts-ignore
    this.linkButton!.reset();
  }

  get linkButton() {
    return this.querySelector('app-link-button');
  }

  @property({type: String})
  private bgimage: '';

  public render() {
    const { bgimage } = this;

    return html`
      <djx-image
        cover
        full-size
        parallax
        src="${bgimage}"
        class="background"
        @click="${this.handleBackgroundClick}"
        @mouseenter="${this.handleMouseEnter}"
        @mouseleave="${this.handleMouseLeave}"
      >
        <div class="button">
          <slot name="button"></slot>
        </div>
        <div class="content">
          <djx-router-link>
            <djx-text size="mdl" lh-sm white>
              <slot></slot>
            </djx-text>
          </djx-router-link>
        </div>
      </djx-image>
    `;
  }
}
