import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import styles from './index.scss';
import prefix from '../prefix';

@customElement(`${prefix}-toggler`)
export class Toggler extends LitElement {
  public static styles = styles;
  @property({type: Boolean, reflect: true})
  private visible = false;

  public render() {
    return this.visible ? html`<slot></slot>` : html``;
  }
}
