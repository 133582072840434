import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './team-staff.scss';
import { ItemsFilter, FiltereableItem } from '../../../filter/items-filter';

@customElement(`${prefix}-team-staff`)
export class TeamStaff extends LitElement {
  public static styles = styles;
  private filter: ItemsFilter;

  constructor() {
    super();
    this.filter = new ItemsFilter({
      maxItemsPerPage: Infinity,
      incrementSize: Infinity,
      onAfterFilterUpdate: () => {},
    });
  }

  private resetItems() {
    const { filter } = this;
    filter.resetMaxItems();
    filter.allCategories();
    filter.clearSlugs();
    filter.update();
  }

  private handleFilterChange(event: CustomEvent) {
    const { filter } = this;
    filter.resetMaxItems();
    if (event.detail.filter === 'all') {
      filter.allCategories();
    } else {
      filter.clearCategories();
      filter.addCategoryFilter(event.detail.filter);
    }
    filter.update();
  }

  private registerItem(event: CustomEvent) {
    const target = event.target as FiltereableItem;
    this.filter.registerItem(target);
    this.filter.registerCategory(target.catid);
    this.resetItems();
  }

  public render() {
    return html`
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="24" lg="[20,2]">
            <djx-spacer size="md"></djx-spacer>
            <slot name="filter" @filterchange=${this.handleFilterChange}></slot>
          </djx-grid-col>
        </djx-grid-row>
        <djx-grid-row>
          <djx-grid-col xs="[16,8]" md="[11, 13]" lg="[7,17]">
            <div class="glyph">
            <slot name="glyph"></slot>
            </div>
          </djx-grid-col>
        </djx-grid-row>
        <djx-grid-row>
          <djx-grid-col xs="24" lg="[20,2]">
            <slot @staffitemregister=${this.registerItem}></slot>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
    `;
  }
}
