import {LitElement, html} from 'lit';
import {customElement, state} from 'lit/decorators.js';
import ClassBreakpoints from '../../../utils/class-breakpoints';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-project-about`)
export class ProjectAbout extends LitElement {
  public static styles = styles;
  private breakpoints: ClassBreakpoints;

  @state()
  private isDesktop = false;

  public connectedCallback() {
    super.connectedCallback();
    this.breakpoints = new ClassBreakpoints(this, {
      'mobile': { max: 1024 },
      'desktop': { min: 1024 },
    }, this.onBreakpointChange.bind(this));
    this.wrapVideos();
  }

  private onBreakpointChange(name: string) {
    this.isDesktop = name === 'desktop';
  }

  get extendedDescription() {
    return html`
      <djx-grid-row>
        <djx-grid-col xs="24" xl="[16,5]">
          <slot name="extended-description"></slot>
        </djx-grid-col>
      </djx-grid-row>
    `;
  }

  private wrapVideos() {
    const iframes = Array.from(this.querySelectorAll('iframe'));
    iframes.forEach((iframe) => {
      if (!iframe.parentElement?.classList.contains('video-wrapper')) {
        const wrapper = document.createElement('div');
        iframe.parentElement?.appendChild(wrapper);
        wrapper.setAttribute('style', `
          position: relative;
          height: 0;
          padding-bottom: 56.25%;
          margin: 20px 0;
        `);
        iframe.setAttribute('style', `
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `);
        wrapper.appendChild(iframe);
      }
    })
  }

  public render() {
    return html`
      <djx-grid>

        <djx-grid-row>
          <djx-grid-col xs="24" xl="[19,3]">
            <div class="intro">
              <slot></slot>
            </div>
            <djx-spacer size="lg"></djx-spacer>
          </djx-grid-col>
        </djx-grid-row>

        <djx-grid-row>
          <djx-grid-col xs="24" lg="8" xl="[9,2]">
            <slot name="featured-image"></slot>
            <div class="image-description-wrapper">
              <slot name="featured-image-description"></slot>
            </div>
            <djx-spacer size="md"></djx-spacer>
            ${ this.isDesktop ? this.extendedDescription : '' }
          </djx-grid-col>

          <djx-grid-col xs="24" lg="[5,1]" xl="[3, 1]" class="offset-1">
            <div class="specs">
              <slot name="specs"></slot>
            </div>
            <div class="links">
              <slot name="links"></slot>
            </div>
          </djx-grid-col>

          <djx-grid-col xs="24" lg="[8,1]" xl="[6, 1]" class="offset-2">
            <div class="description">
              <slot name="description"></slot>
              ${ this.isDesktop ? '' : this.extendedDescription }
            </div>
            <div class="button">
              <slot name="button"></slot>
            </div>
          </djx-grid-col>
        </djx-grid-row>

      </djx-grid>
    `;
  }
}
