import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './menu-mobile.scss';

@customElement(`${prefix}-menu-mobile`)
export class MenuMobile extends LitElement {
  public static styles = styles;

  @property({ type: String })
  private color = '';

  @property({ type: String })
  private url = '';

  @property({ type: String })
  private logourl = '';


  private toggle(visible: boolean) {
    this.classList.toggle('open', visible);
    document.body.classList.toggle('scroll-lock', visible);
  }

  private onMenuOpen(e: MouseEvent) {
    e.preventDefault();
    this.toggle(true);
  }

  private onMenuClose(e: MouseEvent) {
    e.preventDefault();
    this.toggle(false);
  }

  private handleClick() {
    this.toggle(false);
  }

  get inlineStyle() {
    return html`
      <style>
        .menu-btn {
          --text-color: ${this.color};
        }
      </style>
    `;
  }

  public render() {
    const { url, logourl, inlineStyle } = this;
    return html`
      ${inlineStyle}
      <djx-grid-row class="menu">
        <djx-grid-col xs="24" class="end-xs middle-xs">
          <djx-text size="md" uppercase weight="medium">
            <a href="#" class="menu-btn" @click=${this.onMenuOpen}>
              menu
            </a>
          </djx-text>
        </djx-grid-col>
      </djx-grid-row>

      <div class="full-menu">
        <div class="full-menu__overlay"></div>
        <djx-grid>
          <djx-grid-row>
            <djx-grid-col xs="8">
              <djx-router-link>
                <a href="${url}" class="logo" @click="${this.handleClick}">
                  <img src="${logourl}"/>
                </a>
              </djx-router-link>
            </djx-grid-col>
            <djx-grid-col xs="16" class="end-xs middle-xs">
              <djx-text size="md" black uppercase weight="medium">
                <a href="#" class="close-btn" @click=${this.onMenuClose}>
                  close
                </a>
              </djx-text>
            </djx-grid-col>
          </djx-grid-row>
          <djx-grid-row>
            <djx-grid-col xs="[20,4]">
              <div class="glyph">
                <slot name="glyph"></slot>
              </div>
            </djx-grid-col>
          </djx-grid-row>
          <djx-grid-row>
            <djx-grid-col xs="24">
              <div class="full-menu__content">
                <slot @click="${this.handleClick}"></slot>
                <div class="content__copy">
                  <slot name="copy"></slot>
                </div>
              </div>
            </djx-grid-col>
          </djx-grid-row>
        </djx-grid>
      </div>

    `;
  }
}
