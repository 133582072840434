import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './home-areas-item.scss';

@customElement(`${prefix}-home-areas-item`)
export class HomeAreasItem extends LitElement {
  public static styles = styles;

  @property({type: String})
  private bgimage = '';

  @property({type: String})
  private color = '';

  private handleMouseOver() {
    this.dispatchEvent(new CustomEvent('areaitemover', {
      bubbles: true,
    }));
  }

  public render() {
    return html`
      <li class="list-item">
        <djx-router-link>
          <djx-text weight="medium" size="xxls">
            <slot @mouseover="${this.handleMouseOver}"></slot>
          </djx-text>
        </djx-router-link>
      </li>
    `;
  }
}
