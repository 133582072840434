import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './video-player-reel-item.scss';

let slideId = 0;
@customElement(`${prefix}-video-player-reel-item`)
export class ComponentVideoPlayerReelItem extends LitElement {
  public static styles = styles;

  @property({ type: String })
  private youtubeurl = '';

  @property({ type: String })
  private titletext = '';

  @property({ type: String })
  private subtitletext = '';

  @property({ type: Boolean })
  private videoselected = false;

  @property({ type: String })
  private coverimage = '';

  @property({ type: String })
  public pageurl = '';

  firstUpdated() {
    const { pageurl, youtubeurl, titletext, subtitletext, videoselected, coverimage } = this;
    this.dispatchEvent(new CustomEvent('videoregister', {
      bubbles: true,
      detail: {
        id: `yt-slide-${slideId++}`,
        url: youtubeurl,
        title: titletext,
        subtitle: subtitletext,
        current: videoselected,
        image: coverimage,
        pageurl
      }
    }));
  }

  connectedCallback() {
    super.connectedCallback();
  }

  public render() {
    return html`
      <slot></slot>
    `;
  }
}
