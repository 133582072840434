// borrowed from https://davidwalsh.name/javascript-debounce-function
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

export function debounce(callback: Function, wait: number, immediate: boolean): any {
	var timeout: number | undefined;
	return function() {
      // @ts-ignore
    var context = this;
    var args = arguments;
		var later = function() {
			timeout = undefined;
			if (!immediate) callback.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait) as any;
		if (callNow) callback.apply(context, args);
	};
};