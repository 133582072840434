import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-page-glyph`)
export class PageGlyph extends LitElement {
  public static styles = styles;

  @property({type: Boolean, attribute: 'size-xl'})
  private sizexl = false;

  @property({type: Boolean, attribute: 'size-md'})
  private sizemd = false;

  @property({type: String, attribute: 'xs-top' })
  private xstop = '';

  @property({type: String, attribute: 'sm-top' })
  private smtop = '';

  @property({type: String, attribute: 'md-top'})
  private mdtop = '';

  @property({type: String, attribute: 'lg-top'})
  private lgtop = '';

  @property({type: String})
  private color = '';

  updated() {
    this.classList.toggle('size-md', this.sizemd);
    this.classList.toggle('size-xl', this.sizexl);
  }

  get inlineStyle() {
    const { color, smtop, mdtop, lgtop, xstop } = this;
    return `
        ::slotted(svg) {
          fill: ${color} !important;
        }
        :host {
          --xs-top: ${xstop};
          --sm-top: ${smtop || xstop};
          --md-top: ${mdtop || smtop || xstop};
          --lg-top: ${lgtop || mdtop || smtop || xstop};
        };
    `;
  }

  public render() {
    return html`
      <style>
        ${this.inlineStyle}
      </style>
      <div class="container">
        <djx-parallax-item offset="0.35">
          <slot></slot>
        </djx-parallax-item>
      </div>
    `;
  }
}
