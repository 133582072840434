import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './categories-filter.scss';

@customElement(`${prefix}-categories-filter`)
export class CategoriesFilter extends LitElement {
  public static styles = styles;

  @property({ type: String, reflect: true })
  private filter = 'all';

  handleClick(e: MouseEvent) {
    const category = (e.target as any)?.category;
    this.selectCategory(category);
  }

  notifyFilterChange() {
    this.dispatchEvent(new CustomEvent('filterchange', {
      bubbles: true,
      detail: {
        filter: this.filter,
      }
    }));
  }

  selectCategory(value: string) {
    const nodeList = this.querySelectorAll('app-categories-filter-item');
    Array.from(nodeList).forEach(el => {
      const { category } = el as any;
      if (category === value) {
        if (this.filter !== value) {
          this.filter = value;
          this.notifyFilterChange();
        }
      }
      (el as any).selected = value === category;

    });
  }

  public render() {
    const { handleClick } = this;
    return html`
      <div class="label">
        <slot name="filter-text"></slot>
      </div>
      <slot slot="categories" @itemselected=${handleClick}></slot>
    `;
  }
}
