import { EventEmitter, listenCompose, listenEl } from '@smoovy/event';
import { distance } from '../utils/math';

type MixedPointerEvent = MouseEvent | TouchEvent;

export interface PointerEventsConstructorParams {
  target?: EventTarget,
  parent?: EventTarget,
  tapThreshold?: number;
  tapDelay?: number;
}

export interface PointerEvent {
  pageX: number;
  pageY: number;
  clientX: number;
  clientY: number;
  screenX: number;
  screenY: number;
  target: EventTarget;
  type: string;
  event: MouseEvent | TouchEvent;
}

export class PointerEvents extends EventEmitter {
  private target: EventTarget;
  private parent: EventTarget;
  private unbindEvents: Function | null;
  private tapThreshold: number;
  private tapDelay: number;
  private isDown: boolean;
  private lastPosition: number[];
  private startTime: number;

  constructor({
    target = window.document.body,
    parent = window,
    tapThreshold = 10,
    tapDelay = 450,
  }: PointerEventsConstructorParams) {
    super();
    this.target = target;
    this.parent = parent;
    this.tapThreshold = tapThreshold;
    this.tapDelay = tapDelay;
    this.isDown = false;
    this.lastPosition = [0, 0];
    this.startTime = 0;
    this.bind();
  }

  bind() {
    const { parent, target } = this;
    const capture = { passive: true };
    this.unbindEvents = listenCompose(
      listenEl(target as HTMLElement, 'touchstart', this.onStart, capture),
      listenEl(target as HTMLElement, 'mousedown', this.onStart, capture),
      listenEl(parent as HTMLElement, 'mousemove', this.onMove, capture),
      listenEl(parent as HTMLElement, 'touchmove', this.onMove, capture),
      listenEl(parent as HTMLElement, 'touchcancel', this.onEnd, capture),
      listenEl(parent as HTMLElement, 'mouseup', this.onEnd, capture),
      listenEl(parent as HTMLElement, 'touchend', this.onEnd, capture),
    );
  }

  unbind() {
    if (this.unbindEvents) {
      this.unbindEvents();
      this.unbindEvents = null;
    }

  }

  private isTouchEvent(event: any) {
    return event instanceof TouchEvent;
  }

  private normalizeEvent(event: any) {
    if (this.isTouchEvent(event)) {
      event = event.touches[0] || event.changedTouches[0];
    }

    return {
      pageX: event.pageX,
      pageY: event.pageY,
      clientX: event.clientX,
      clientY: event.clientY,
      screenX: event.screenX,
      screenY: event.screenY,
      target: event.target,
      type: event.type || 'touch',
      event,
    } as PointerEvent;
  }

  private isInside(event: PointerEvent) {
    const target = event.target as HTMLElement;
    const { left, right, top, bottom } = target.getBoundingClientRect();
    return event.clientX > left && event.clientX < right &&
      event.clientY > top && event.clientY < bottom;
  }

  private onStart = (event: MixedPointerEvent) => {
    if (this.isDown) return;
    const nEvent = this.normalizeEvent(event);
    this.lastPosition = [nEvent.clientX, nEvent.clientY];
    this.startTime = performance.now();
    this.isDown = true;
    this.emit('down', nEvent);
  }

  private onMove = (event: MixedPointerEvent) => {
    const nEvent = this.normalizeEvent(event);
    if (this.isInside(nEvent)) {
      this.emit('move', nEvent);
    }
    if (this.isDown) {
      this.emit('drag', nEvent);
    }
  }

  private onEnd = (event: MixedPointerEvent) => {
    const { lastPosition, startTime, tapThreshold, tapDelay} = this;
    const nEvent = this.normalizeEvent(event);
    const pos = [nEvent.clientX, nEvent.clientY];
    const dst = distance(pos, lastPosition);
    const elapsedTime = performance.now() - startTime;
    if (this.isDown) {
      this.emit('up', nEvent);
    }

    if (this.isInside(nEvent) && Math.abs(dst) < tapThreshold && elapsedTime < tapDelay) {
      this.emit('tap', nEvent);
    }
    this.isDown = false;
  }
}