import { ActionArgs, RouterTransition } from '@smoovy/router';
import scroller from '../scroller';

interface TransitionComponent extends Element {
  transitionOut: Function;
  transitionIn: Function;
}

export default class GenericTransition extends RouterTransition {
  private transition: TransitionComponent;

  constructor() {
    super();
    const el = document.querySelector('app-page-transition') as TransitionComponent;
    if (el) {
      this.transition = el;
    }
  }

  public async beforeEnter() {
    return this.transition.transitionIn();
  }
  public async beforeLeave() {}

  public async afterEnter(config: ActionArgs): Promise<void> {}

  public async afterLeave(config: ActionArgs): Promise<void> {
    return new Promise( async (resolve) => {
      scroller.scrollTo(0, true);
      await this.transition.transitionOut();
      resolve();
    });
  }
}