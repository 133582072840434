import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-about-intro`)
export class AboutIntro extends LitElement {
  public static styles = styles;

  public render() {
    return html`
    <djx-spacer size="lg"></djx-spacer>
    <djx-grid>
      <djx-grid-row>
        <djx-grid-col xs="24" lg="[18,3]">
          <slot></slot>
        </djx-grid-col>
        <djx-grid-col xs="[12,12]" md="[8,14]" lg="[7,16]">
          <slot name="glyph"></slot>
        </djx-grid-col>
      </djx-grid-row>
    </djx-grid>
    `;
  }
}
