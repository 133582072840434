import {LitElement, html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './video-player-reel.scss';
import gsap from 'gsap';

@customElement(`${prefix}-video-player-reel`)
export class ComponentVideoPlayerReel extends LitElement {
  public static styles = styles;
  private notificationVisible = false;
  private videos: any = [];

  @property({ type: String })
  public currenturl = '';

  @property({ type: String })
  public currentVideo: String;

  @query('#notification')
  public notification: HTMLElement;

  @query('#clipboard-text')
  public clipboardText: HTMLElement;

  connectedCallback() {
    super.connectedCallback();
  }

  onVideoRegister(event: CustomEvent) {
    this.videos.push(event.detail);
  }

  firstUpdated() {
    setTimeout(() => {
      this.requestUpdate();
    });
  }

  updateSlideUrl(currentSlide: any, prevSlide: any) {
    const targetUrl = currentSlide.getAttribute('pageurl');
    const currentUrl = window.location.href;
    if (targetUrl !== currentUrl && prevSlide !== undefined) {
      window.history.replaceState(null, '', targetUrl);
    }
  }

  onSlideChange(event: CustomEvent) {
    const { curr, prev } = event.detail;
    this.updateSlideUrl(curr, prev);

    const players = this.renderRoot.querySelectorAll('app-video-player');
    players.forEach((player: any) => player.toggleButton(false));

    if (prev) {
      const prevVideo = prev.querySelector('app-video-player');
      if (prevVideo) {
        prevVideo.stop();
      }
    }

    if (curr) {
      this.currenturl = curr.getAttribute('pageurl');
      this.updateCurrentTitle(curr);
      const player = curr.querySelector('app-video-player');
      if (player) {
        player.introButton();
      }
    }

    this.requestUpdate();
  }

  onShareClick(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.copyClipboardUrl();
    this.showNotification();
  }

  updateCurrentTitle(slide: any) {
    const transitions = this.renderRoot.querySelectorAll('.title-transition');
    const id = slide.getAttribute('id');
    transitions.forEach((tnt) => {
      (tnt as any).style.display = 'none';
      if (tnt.id === id) {
        (tnt as any).style.display = 'block';
        (tnt as any).reset();
        (tnt as any).transitionIn();
      }
    });
  }

  showNotification() {
    if (this.notificationVisible) return;
    const notification = this.querySelector('app-page-notification')!;
    const parent = notification.parentElement;
    document.body.appendChild(notification);
    const tl = gsap.timeline({
      onComplete: () => {
        parent?.appendChild(notification);
        this.notificationVisible = false;
      }
    });
    tl.fromTo(notification, {
      y: -200,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      duration: 0.5,
      ease: 'Power2.easeOut'
    });

    tl.to(notification, {
      opacity: 0,
      delay: 3,
      duration: 0.5,
      ease: 'Power2.easeOut'
    });
  }

  copyClipboardUrl() {
    const selection = window.getSelection();
    const range = document.createRange();
    selection!.removeAllRanges();
    range.selectNode(this.clipboardText);
    selection?.addRange(range);
    try {
      window.document.execCommand('copy');
    } finally {
      selection?.removeAllRanges();
    }

  }

  getSlidesTemplate() {
    const slides = this.videos.map((video: any, index: number) => {
      return html`
        <app-page-slider-item
          id="${video.id}"
          pageurl="${video.pageurl}"
        >
          <djx-text size="lg" weight="light" color="white" class="slide-number">
            ${index + 1}
          </djx-text>
          <app-video-player
            trigger="manual"
            src="${video.url}"
            lazyload
            poster="${video.image}"
          >
          </app-video-player>
        </app-page-slider-item>
      `;
    });

    const index = this.videos.findIndex((video: any) => video.current === true);

    return html`<app-page-slider
      @slidechange="${this.onSlideChange}"
      startindex="${index}"
      nopagination
      infinite
      autoheight
    >${slides}</app-page-slider>`;
  }

  getHeadersTemplate() {
    return this.videos.map((video: any) => {
      return html`
        <djx-text-transition id='${video.id}' trigger='manual' selector='djx-text' mode="words" class="title-transition center">
        <djx-spacer size="xs"></djx-spacer>
          <djx-text xs="xxl" md="xxl" weight="medium" lh-sm>${video.title}</djx-text>
          <djx-text size="lg" type='secondary' weight="medium" lh-md>${video.subtitle}</djx-text>
        </djx-text-transition>
      `;
    });
  }

  public render() {
    return html`
      <div class="clipboard-notification">
        <span id="clipboard-text">${this.currenturl}</span>
        <slot name="clipboard-text"></slot>
      </div>
      <!-- TITLE HEADER -->
      <app-page-header>
        <h1>
          ${this.getHeadersTemplate()}
        </h1>
      </app-page-header>
      <slot
        @videoregister="${this.onVideoRegister}"
      ></slot>
      <div class="slider-wrapper">
        <djx-spacer size="xs"></djx-spacer>
        ${this.getSlidesTemplate()}
        <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="24" xl="[4,6]" class="center-xs">
            <djx-breakpoint width="min-lg">
              <djx-spacer size="xs"></djx-spacer>
            </djx-breakpoint>
            <djx-router-link>
              <djx-text size="sm" black uppercase underline underlineboldhover>
                <slot name="backlink"></slot>
              </djx-text>
            </djx-router-link>
            <djx-breakpoint width="min-lg">
              <djx-spacer size="xxxxs"></djx-spacer>
            </djx-breakpoint>
          </djx-grid-col>
          <djx-grid-col xs="24" xl="[6,1]" class="end-xl center-xs">
            <djx-text size="sm" black uppercase underline underlineboldhover>
              <a href="mailto:someone@example.com?subject=${this.currenturl}">
                <slot name="email-text"></slot>
              </a>
            </djx-text>&nbsp;&nbsp;&nbsp;
            <djx-breakpoint width="min-lg">
              <djx-spacer size="xxxxs"></djx-spacer>
            </djx-breakpoint>
            <djx-text size="sm" black uppercase underline underlineboldhover>
              <a href="#share" @click="${this.onShareClick}">
                <slot name="link-share"></slot>
              </a>
            </djx-text>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
      <djx-spacer size="xs"></djx-spacer>
    </div>
    `;
  }
}
