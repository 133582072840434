import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './page-slider-item.scss';

@customElement(`${prefix}-page-slider-item`)
export class PageSliderItem extends LitElement {
  public static styles = styles;

  @property({ type: Number })
  public index = 0;

  public render() {
    return html`
      <slot></slot>
      <div class="description">
        <slot name="description"></slot>
      </div>
    `;
  }
}
