import { waitForProperties } from '../scripts/utils/wait-for';

export async function loadMap(key: string) {
  if (document.querySelector('#gmscript') === null) {
    const script = document.createElement('script');
    script.id = 'gmscript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${key}`;
    script.defer = true;
    script.async = true;
    document.head.appendChild(script);
  }
  return waitForProperties(window, ['google.maps']);
}