// Waits for a global property in the target before resolving the promise
export async function waitForProperties(target: any, props: string[]) {
  const pathExists = function( arr: string[], obj: any ): boolean {
      var target = obj[arr.shift() as string];
      var exists = target !== undefined;
      return arr.length == 0 ? exists : exists && pathExists(arr, target);
  };
  const predicate = function() {
      return props.every(function(path: string) {
          return pathExists(path.split('.'), target);
      });
  };
  await waitFor(predicate);
};

/**
* Wait for a predicate to become true before resolving the promise
* @param {function} predicate - The function to evaluate
* @param {number} maxWait - Max time to wait before the promise gets rejected
*/
export async function waitFor(predicate: () => boolean, maxWait = Infinity) {
  return new Promise((resolve, reject) => {
    let timeLeft = maxWait;
    const wait = function() {
    setTimeout( function() {
      predicate() && resolve(true) || wait();
      timeLeft -= 16
      if (timeLeft < 0) {
        reject();
      }
      }, 16);
    };
    wait();
  })
}