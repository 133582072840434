import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-jobs-cover`)
export class JobsCover extends LitElement {
  public static styles = styles;

  @property({ type: String })
  private bgimage = '';

  public render() {
    return html`
    <djx-grid>
      <djx-grid-row>
        <djx-grid-col xs="24" lg="[16,4]">
          <slot name="title"></slot>
          <slot></slot>
        </djx-grid-col>
      </djx-grid-row>
    </djx-grid>
    <div class="cover">
      <djx-image cover full-size parallax zoom zoom-in src="${this.bgimage}"></djx-image>
    </div>
    `;
  }
}
