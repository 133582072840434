import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-project-about-specs`)
export class ProjectAboutSpecs extends LitElement {
  public static styles = styles;

  public render() {
    return html`
      <slot></slot>
    `;
  }
}
