import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './page-link-list-item.scss';

@customElement(`${prefix}-page-link-list-item`)
export class PageLinkListItem extends LitElement {
  public static styles = styles;

  @property({type: String})
  private color = '';

  public render() {
    const { color } = this;
    return html`
      <div class="container">
        <djx-text size="f16" weight="medium">
          <slot></slot>
        </djx-text>
        <djx-text
          .color="${color}"
          size="f16"
          weight="medium"
          uppercase
          underline
          underlineboldhover
        >
          <slot name="link"></slot>
        </djx-text>
      </div>
    `;
  }
}
