import {LitElement, html} from 'lit';
import {property, customElement, state} from 'lit/decorators.js';
import ClassBreakpoints from '../../../utils/class-breakpoints';

import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-area-about`)
export class AreaAbout extends LitElement {
  public static styles = styles;
  private breakpoints: ClassBreakpoints;

  @state()
  private isDesktop= false;

  @property({type: String })
  private color = '';

  get inlineStyle() {
    const { color } = this;
    return html`
      <style>
        .menu {
          --menu-line-color: ${color};
          --text-underline-color: ${color};
          --text-color: ${color};
        }
      </style>
    `;
  }

  get listSlot() {
    return html`
      <djx-grid-col xs="24" lg="[16, 4]" class="list-top-col">
        <slot name="list"></slot>
      </djx-grid-col>
    `
  }

  get auxContent() {
    return html`
      <djx-grid-row>
        <djx-grid-col xs="24" lg="[12, 5]">
          <djx-text size="sm" lh-lg>
            <slot name="aux-content"></slot>
          </djx-text>
        </djx-grid-col>
      </djx-grid-row>
    `;
  }

  public connectedCallback() {
    super.connectedCallback();
    this.breakpoints = new ClassBreakpoints(this, {
      'mobile': { max: 1024 },
      'desktop': { min: 1024 },
    }, this.onBreakpointChange.bind(this));
  }

  private onBreakpointChange(name: string) {
    this.isDesktop = name === 'desktop';
  }

  public render() {
    return html`
      ${this.inlineStyle}
      <djx-grid>
        <djx-grid-row class="mobile-reverse">

          <!-- Content -->
          <djx-grid-col xs="24" lg="[7, 1]" class="max-width">
            <djx-grid class="max-width">

              <djx-grid-row class="no-margin">
                <djx-grid-col xs="24" lg="[23, 1]" class="max-width">
                  <slot name="title"></slot>
                </djx-grid-col>
              </djx-grid-row>

              <djx-grid-row class="no-margin">
                <djx-grid-col xxs="24" lg="[19, 4]" class="max-width">
                    <djx-text size="sm" lh-lg>
                      <slot></slot>
                    </djx-text>
                  </djx-transition>
                </djx-grid-col>
              </djx-grid-row>

            </djx-grid>
          </djx-grid-col>

          <!-- Menu -->

          <djx-grid-col xs="24" lg="16">
            <djx-grid-row>

              <djx-grid-col xs="24" lg="[8, 4]" xl="[8, 4]">
                <div class="menu">
                  <slot name="menu"></slot>
                </div>
              </djx-grid-col>

              <djx-grid-col xs="24" lg="[10, 2]" class="glyph-col">
                <div class="glyph">
                  <slot name="glyph"></slot>
                </div>
              </djx-grid-col>
              ${this.isDesktop ? this.listSlot : ''}
            </djx-grid-row>
            <djx-spacer size="md"></djx-spacer>
            ${this.isDesktop ? this.auxContent : ''}
          </djx-grid-col>
        </djx-grid-row>
        ${this.isDesktop ? '' : this.auxContent}
        <djx-grid-row>
          <djx-grid-col xs="24">
            ${this.isDesktop ? '' : this.listSlot}
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
    `;
  }
}
