import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-nav`)
export class Nav extends LitElement {
  public static styles = styles;

  @property()
  private url: string;

  @property()
  private logourl: string;

  private get logo() {
    const { url, logourl } = this;
    return html`
      <djx-router-link>
        <a href="${url}" class="logo">
          <img src="${logourl}"/>
        </a>
      </djx-router-link>
    `
  }

  public render() {
    return html`
      <nav>
        <djx-grid class="max-width">
          <djx-grid-row class="between-xs">
            <djx-grid-col>
              ${this.logo}
            </djx-grid-col>
            <djx-grid-col class="menu-col">
              <slot name="menu"></slot>
            </djx-grid-col>
          </djx-grid-row>
        </djx-grid>
      </nav>
    `;
  }
}
