import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './categories-item.scss';

@customElement(`${prefix}-categories-filter-item`)
export class CategoriesItem extends LitElement {
  public static styles = styles;

  @property({type: Boolean})
  private selected = false;

  @property({type: String})
  private category = '';

  handleClick(e: MouseEvent) {
    const event = new CustomEvent('itemselected', {
      bubbles: true,
      composed: true,
      detail: { category: this.category },
    });
    this.dispatchEvent(event);
    e.preventDefault();
  }

  public render() {
    const { handleClick, category, selected } = this;
    return html`
      <djx-text uppercase black greenhover underlinegreen underlinehover .underlinebold="${selected}">
          <a class="anchor" href="#${category}" @click="${handleClick}">
            <slot></slot>
          </a>
      </djx-text>
    `;
  }
}
