import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-border-image`)
export class BorderImage extends LitElement {
  public static styles = styles;

  @property({ type: String })
  private src = '';

  public render() {
    return html`
      <djx-image cover auto-height parallax src="${this.src}">
        <slot></slot>
      </djx-image>
    `;
  }
}
