import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-border-button`)
export class BorderButton extends LitElement {
  public static styles = styles;

  @property({ type: String })
  private color = '';

  get inlineStyle() {
    return html`
      <style>
        :host {
          background-color: ${this.color};
        }
      </style>
    `
  }

  public render() {
    return html`
      ${this.inlineStyle}
      <djx-router-link>
        <slot></slot>
      </djx-router-link>
    `;
  }
}
