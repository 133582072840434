import {LitElement, html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import scroller from '../../../scroller';
import prefix from '../prefix';
import styles from './index.scss';
import gsap, { TweenMax } from 'gsap';
import { clamp } from '../../../utils/math';
import { debounce } from '../../../utils/debounce';
import { elementPosition } from '../../../utils/element-position';

const AttributeFlags = [
  'cover',
  'contain',
  'auto-height',
  'auto-width',
  'full-width',
  'full-height',
  'full-size',
  'parallax',
  'zoom',
  'zoom-reverse',
  'zoom-in',
];

@customElement(`${prefix}-image`)
export class DjxImage extends LitElement {
  public static styles = styles;
  private image = new Image();
  private zoomingOut = false;
  private elementPosition = [0, 0];
  private elementHeight = 0;
  private elementWidth = 0;
  private windowHeight = 0;
  private windowWidth = 0;

  @query('.background')
  private background: HTMLElement;

  @property({ type: Boolean })
  private loaded = false;

  @property({ type: Boolean })
  private nopreload = false;

  @property()
  private src: string = '';

  @property({ type: Boolean })
  private bgcolor = 'transparent';

  static get properties() {
    const props: any = {};
    AttributeFlags.forEach((attr: string) => {
      props[attr] = {
        attribute: true,
        reflect: true,
        type: Boolean,
      };
    })
    return props;
  }

  connectedCallback() {
    super.connectedCallback();
    scroller.on('scroll', this.updateParallax);
    document.addEventListener('loaded', this.zoomIn);
    window.addEventListener('resize', this.updateElementPosition);
    this.updateParallax();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    scroller.off('scroll', this.updateParallax);
    window.removeEventListener('resize', this.updateElementPosition);
  }

  updateElementPosition() {
    this.elementPosition = elementPosition(this);
    const { height, width } = this.getBoundingClientRect();
    this.elementHeight = height;
    this.elementWidth = width;
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  }

  private notifyLoad() {
    this.dispatchEvent(new CustomEvent('imageloaded', {
      bubbles: true,
      composed: true,
      detail: {
        image: this.image
      },
    }));
  }

  private load() {
    const { image } = this;
    if (this.src === image.src || this.loaded) {
      return;
    }
    document.dispatchEvent(new CustomEvent('preloaderimageloadstart'));
    this.loaded = false;
    image.src = this.src;
    if (this.nopreload) {
      this.loaded = true;
      document.dispatchEvent(new CustomEvent('preloaderimageload'));
    }

    image.addEventListener('load', () => {
      if (!this.nopreload) {
        this.loaded = true;
        document.dispatchEvent(new CustomEvent('preloaderimageload'));
      }
        this.classList.toggle('loaded', this.loaded);
        this.notifyLoad();
        setTimeout(() => {
          this.updateElementPosition();
          this.updateParallax();
        }, 100);
      });
  }

  updated() {
    this.load();
    AttributeFlags.forEach((attr: string)  => {
      const val = (this as any)[attr];
      this.classList.toggle(attr, !!val);
    });
    if (!this.loaded && this.classList.contains('zoom-in')) {
      TweenMax.to(this.background, 0, { opacity: 0, immediateRender: true });
    }
  }

  zoomIn = () => {
    if (this.classList.contains('zoom-in')) {
      this.zoomingOut = true;
      TweenMax.fromTo(this.background, 1.7,
        {
          scale: 1.5,
        },
        {
          opacity: 1,
          scale: 1.15,
          onComplete: () => {
            this.zoomingOut = false;
          }
        });
    }
  }

  updateParallax = () => {
    const isParallax = this.classList.contains('parallax');
    const isZoom = this.classList.contains('zoom');
    const isZoomReverse = this.classList.contains('zoom-reverse');
    if (this.zoomingOut) return false;
    if (isParallax || isZoom || isZoomReverse) {
      if (isZoom) {
        this.updateElementPosition();
      }
      const { windowWidth: w, windowHeight: h } = this;
      const { elementWidth: eW, elementHeight: eH } = this;
      const [elX, elY] = this.elementPosition;
      const y = ((elY - scroller.scrollY + eH / 2) / h - 0.5) * eH * 0.15;
      if (isZoom) {
        let scaleOffset = clamp(y * -0.002, 0, 0.15);
        TweenMax.to(this.background, 0.75, {
          y: -y,
          scale: 1.15 - scaleOffset,
        });
      }
      if (isParallax) {
        TweenMax.to(this.background, 0.25, {
          y: -y,
          scale: 1.1,
        });
      }
      if (isZoomReverse) {
        let scaleOffset = clamp(y * -0.002, 0, 0.15);
        TweenMax.to(this.background, 0.25, {
          y: -y,
          scale: 1 + scaleOffset,
        });
      }
    }
  }

  get backgroundStyle() {
    return styleMap({
      // backgroundColor: this.bgcolor,
      backgroundImage: `url(${this.src})`,
    })
  }

  get autoHeightStyle() {
    const { image } = this;
    const { naturalWidth: w, naturalHeight: h } = image;
    if (!(this as any)['auto-height'] ) return;
    return html`
      <style>
        .background {
          padding-bottom: ${h / w * 100}%;
        }
      </style>
    `;
  }

  get autoWidthElement() {
    if (!(this as any)['auto-width'] ) return;
    return html`<img src="${this.src}" draggable="false">`;
  }

  public render() {
    const { loaded, backgroundStyle } = this;
    return html`
      ${this.autoHeightStyle}
      <djx-spinner .visible=${!loaded}></djx-spinner>
      <div class="background-container ${classMap({ loaded })}">
        <div
          class="background"
          style="${ backgroundStyle }"
        ></div>
        ${this.autoWidthElement}
        <slot></slot>
      </div>
    `;
  }
}
