import {LitElement, html} from 'lit';
import {property, customElement, query, state} from 'lit/decorators.js';
import ClassBreakpoints from '../../../utils/class-breakpoints';
import { TweenMax, gsap } from 'gsap';
import { lerp } from '../../../utils/math';
import prefix from '../prefix';
import styles from './area-project-list.scss';

@customElement(`${prefix}-area-project-list`)
export class AreaProjectList extends LitElement {
  public static styles = styles;
  private breakpoints: ClassBreakpoints;
  private previewTargetX: number = 0;
  private previewTargetY: number = 0;

  @query('.preview')
  private preview: HTMLElement;

  @property({ type: Number })
  private current: HTMLElement;

  @state()
  private previews: HTMLElement[] = [];

  connectedCallback() {
    super.connectedCallback();
    this.breakpoints = new ClassBreakpoints(this, {
      'desktop': { min: 1024 },
    });
    gsap.ticker.add(this.updatePreviewPosition);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    gsap.ticker.remove(this.updatePreviewPosition);
  }

  updatePreviewPosition = (time: number, delta: number, frame: number) => {
    const { preview } = this;
    if (this.classList.contains('desktop')) {
      const xCurrent = gsap.getProperty(preview, 'x') as number;
      const yCurrent = gsap.getProperty(preview, 'y') as number;
      const x = lerp(xCurrent, this.previewTargetX, 0.07);
      const y = lerp(yCurrent, this.previewTargetY, 0.07);
      TweenMax.to(this.preview, 0, { x, y });
    }
  }

  handleMouseMove(event: MouseEvent) {
    const rect = this.getBoundingClientRect();
    this.previewTargetX = event.clientX - rect.left + 80;
    this.previewTargetY = event.clientY - rect.top - 120;
  }

  handleItemEnter(e: CustomEvent) {
    this.current = e.target as HTMLElement;
  }

  handleMouseEnter(event: MouseEvent) {
    const rect = this.getBoundingClientRect();
    const x = event.clientX - rect.left + 120;
    const y = event.clientY - rect.top - 120;
    this.previewTargetX = x;
    this.previewTargetY = y;
    TweenMax.to(this.preview, 0, { immediateRender: true, x, y });
    this.preview.classList.add('visible');
  }

  handleMouseLeave() {
    this.preview.classList.remove('visible');
  }

  registerItem(e: CustomEvent) {
    this.previews.push(e.target as HTMLElement);
    this.requestUpdate('previews');
  }

  get previewImages() {
    return this.previews.map((el: any) => {
      const className = el === this.current ? 'visible' : '';
      return html`<djx-image class="${className}" cover src="${el.imagesrc}"></djx-image>`;
    });
  }


  public render() {
    return html`
      <div class="preview">
        ${this.previewImages}
      </div>
      <slot
        @mouseenter="${this.handleMouseEnter}"
        @mouseleave="${this.handleMouseLeave}"
        @mousemove="${this.handleMouseMove}"
        @itementer="${this.handleItemEnter}"
        @registeritem="${this.registerItem}"
      ></slot>
    `;
  }
}
