import {LitElement, html} from 'lit';
import {property, customElement, state, query} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './page-slider.scss';
import Slider from '../../../slider/slider';
import { QueryDeep } from '../../../utils/query-deep';
const ChildItemTag = 'app-page-slider-item';

@customElement(`${prefix}-page-slider`)
export class PageSlider extends LitElement {
  public static styles = styles;
  private slider: Slider;
  private images: Element[] = [];

  @property({ type: String })
  private type = '';

  @property({ type: String })
  private color = '';

  @property({ type: Boolean })
  private infinite = false;

  @query('.container')
  private container: HTMLElement;

  @property({ type: Number })
  private startindex = 0;

  @state()
  private slidesCount: number;

  @state()
  private slideIndex: number = 0;

  @property({ type: Boolean })
  private nopagination = false;

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.onResize);
  }

  onResize = () => {
    if (this.slider) {
      this.slider.moveToCurrent();
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.onResize);
  }

  public createSlider() {
    const { container } = this;
    if (this.slider) return;
    const items = Array.from(this.querySelectorAll(ChildItemTag));
    this.slider = new Slider(container, {
      infinite: this.infinite
    });
    items.forEach((item, index) => {
      item.setAttribute('index', index.toString());
      this.slider.registerSlide(item as HTMLElement)
    });
    this.slider.on('change', this.onSlideChange);
    this.slider.on('update', this.updateHorizontalParallax);
    this.slidesCount = items.length;
    this.slider.moveToIndex(this.startindex);
  }

  public onSlideChange = (event: any) => {
    this.slideIndex = event.index;
    this.dispatchEvent(new CustomEvent('slidechange', {
      detail: {
        prev: event.previousElement,
        curr: event.currentElement
      }
    }));
  }

  public onProgressDrag = (e: CustomEvent) => {
    this.slider.manualProgress(e.detail.progress);
  }

  updated() {
    if (this.type) {
      this.classList.toggle(this.type, true);
    }

    const timer = setInterval(() => {

      const items = Array.from(this.querySelectorAll(ChildItemTag));
      if (items.length > 0) {
        clearInterval(timer);
        this.createSlider();
        this.images = QueryDeep(this, 'djx-image.parallax');
      } else {
        console.log(ChildItemTag, 'not found')
      }
    }, 100);
  }

  reUpdate() {

  }

  updateHorizontalParallax = () => {
    if (this.images) {
      this.images.forEach(image => {
        (image as any).updateParallax(true);
      })
    }
  }

  getPaginationTemplate() {
    return html`
      <app-page-slider-pagination
        .color="${this.color}"
        .type="${this.type}"
        .count="${this.slidesCount}"
        .index="${this.slideIndex}"
        @dragupdate="${this.onProgressDrag}"
      ></app-page-slider-pagination>
    `;
  }

  public render() {
    return html`
      <div class="container">
        <slot></slot>
      </div>
      ${this.nopagination ? '' : this.getPaginationTemplate()}
    `;
  }
}
