import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './jobs-list-item.scss';

@customElement(`${prefix}-jobs-list-item`)
export class JobsListItem extends LitElement {
  public static styles = styles;

  @property({type: String})
  private catid = '';

  @property({type: Boolean, reflect: true})
  private visible = true;

  public connectedCallback() {
    super.connectedCallback();
    this.notifyRegister();
  }

  private notifyRegister() {
    requestAnimationFrame(() => {
      this.dispatchEvent(new CustomEvent('jobsitemregister', {
        bubbles: true,
      }));
    });
  }

  public render() {
    return html`
      <div class="line"></div>
      <div class="container">
        <djx-grid-row>
          <djx-grid-col xs="24" lg="13" class="title-col">
            <djx-text size="lgl" weight="medium">
              <slot name="title"></slot>
            </djx-text>
          </djx-grid-col>
        </djx-grid-row>
        <djx-grid-row class="bottom-row">
          <djx-grid-col xs="18">
            <djx-text size="lgll">
              <slot name="area"></slot>
            </djx-text>
          </djx-grid-col>
          <djx-grid-col xs="24" lg="6" class="link-col">
            <slot name="link"></slot>
          </djx-grid-col>
        </djx-grid-row>
      </div>
    `;
  }
}
