import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import styles from './index.scss';
import prefix from '../prefix';

@customElement(`${prefix}-spacer`)
export class Spacer extends LitElement {
  public static styles = styles;

  @property()
  private size: string = 'xs';

  public render() {
    return html`
      <div class="spacer size-${this.size}"></div>
    `;
  }
}
