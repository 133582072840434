import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-page-notification`)
export class ComponentPageNotification extends LitElement {
  public static styles = styles;

  public render() {
    return html`
      <div id="notification" class="notification-clipboard">
        <djx-text size="xs" color="white">
          <slot></slot>
        </djx-text>
      </div>
    `;
  }
}
