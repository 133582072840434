import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import { Tween, easings } from '@smoovy/tween';
import { Browser } from '@smoovy/utils';

import styles from './scroller.scss';
import prefix from '../prefix';

@customElement(`${prefix}-scroller`)
export class Scroller extends LitElement {
  public static styles = styles;

  @property({type : Number})
  private duration = 1000;

  private lastY = 0;

  public connectedCallback() {
    super.connectedCallback();

    if (Browser.client && !Browser.mobile) {
      this.style.position = 'fixed';
      window.addEventListener('scroll', () => this.move());
    }
  }

  private move() {
    Tween.fromTo(
      {y: this.lastY},
      {y: window.scrollY},
      {
        duration: this.duration,
        easing: easings.Expo.out,
        overwrite: false,
        mutate: true,
        on: {
          update: (values) => {
            this.lastY = values.y;
            this.style.transform = `translate3d(0px, ${values.y * -1}px, 0px)`;
          },
          complete: () => {
            // this.style.pointerEvents = 'unset';
          }
        }
      }
    );
  }

  public render() {
    return html`<slot></slot>`;
  }
}


