function QueryFromArray(elements: Element[], selector: string) {
  const results: Element[] = [];
  const map = new Map();
  const frag = document.createDocumentFragment();
  elements.forEach(el => {
    const ghost = el.cloneNode();
    frag.appendChild(ghost);
    map.set(ghost, el);
  });
  const ghosts = Array.from(frag.querySelectorAll(selector));
  ghosts.forEach(ghost => results.push(map.get(ghost)));
  return results;
}

function QuerySlot(selector: string, slot: HTMLSlotElement) {
  let results: any[] = [];
  const elements = slot.assignedElements({ flatten: true });
  elements.forEach((el: any) => {
    if (el.assignedElements) {
      results = results.concat(QuerySlot(selector, el));
    } else {
      results.push(el);
    }
  })
  return QueryFromArray(results, selector);
}

export function QueryDeep(target: HTMLElement, selector: string) {
  let results = Array.from(target.querySelectorAll(selector));
  if (target.shadowRoot) {
    results = results.concat(Array.from(target.shadowRoot.querySelectorAll(selector)));
    // remove duplicates results
    results = Array.from(new Set(results));
  }
  const slots = Array.from(target.querySelectorAll('slot'));
  slots.forEach((slot) => {
    results = results.concat(QuerySlot(selector, slot));
  });
  return results;
}