import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-page-contact`)
export class PageContact extends LitElement {
  public static styles = styles;

  public render() {
    return html`
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="24" md="[22, 1]" class="title-col">
            <div class="title">
              <djx-text-transition selector='div, span' mode="words">
                <slot name="title"></slot>
              </djx-text-transition>
            </div>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
      <slot></slot>
      <djx-spacer size="md"></djx-spacer>
    `;
  }
}
