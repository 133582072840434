import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './videos-list-item.scss';
import ClassBreakpoints from '../../../utils/class-breakpoints';

@customElement(`${prefix}-videos-list-item`)
export class ComponentVideosListItem extends LitElement {
  public static styles = styles;
  private breakpoints: ClassBreakpoints;
  private visible = true;

  @property({type: String})
  private overlaycolor = '#127134';

  @property({type: String})
  private bgimage = '';

  public connectedCallback() {
    super.connectedCallback();
    this.breakpoints = new ClassBreakpoints(this, {
      'mobile': { max: 1024 },
      'desktop': { min: 1024 },
    });
  }

  public disconnectedCallback() {
    super.disconnectedCallback();
    this.breakpoints.unbind();
  }

  updated() {
    this.classList.toggle('visible', this.visible);
    if (!this.visible) {
      TweenMax.to(this, 0, { opacity: 0 });
    }
  }

  handleBackgroundClick() {
    if (this.classList.contains('mobile')) {
      if (this.linkButton) {
        (this.linkButton as any).navigate();
      }
    }
  }

  handleMouseEnter() {
    // @ts-ignore
    this.linkButton!.transitionIn();
  }

  handleMouseLeave() {
    // @ts-ignore
    this.linkButton!.reset();
  }

  get linkButton() {
    return this.querySelector('app-link-button');
  }

  get containerStyle() {
    return html`
      <style>
        .background:hover:after {
          background-color: ${this.overlaycolor};
          opacity: 0.5;
        }
      </style>
    `;
  }

  public render() {
    return html`
      ${this.containerStyle}
      <div
        class="background"
        @click="${this.handleBackgroundClick}"
        @mouseenter="${this.handleMouseEnter}"
        @mouseleave="${this.handleMouseLeave}"
      >
        <djx-image
          cover
          src="${this.bgimage}"
        ></djx-image>
        <div class="button">
          <slot name="button"></slot>
        </div>
      </div>
      <djx-spacer size="xxxs"></djx-spacer>
      <slot name="title"></slot>
      <slot name="subtitle"></slot>
    `;
  }
}
