import {LitElement, html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';



@customElement(`${prefix}-search-box`)
export class SearchBox extends LitElement {
  public static styles = styles;

  @query('#searchbox')
  private searchbox: HTMLInputElement;

  @property({type: String})
  private placeholder = '';

  private handleKeyUp(e: KeyboardEvent) {
    if (e.keyCode === 13) {
      this.dispatchEvent(new CustomEvent('searchenter', {
        bubbles: true,
        detail: {
          query: this.searchbox.value,
        }
      }));
    }
  }

  public render() {
    const { placeholder } = this;
    return html`
      <slot name="icon"></slot>
      <input id="searchbox" type="text" placeholder="${placeholder}" @keyup="${this.handleKeyUp}" >
    `
  }
}
