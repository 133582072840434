import {LitElement, html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';

import { ViewportObserver, ViewportObservable } from '@smoovy/observer';
import { Size } from '@smoovy/utils';

import styles from './index.scss';
import prefix from '../prefix';

type WidthKey = keyof typeof BreakpointWidth;

export enum BreakpointWidth {
  XS = 319,
  SM = 449,
  MD = 767,
  LG = 1023,
  XL = 1359,
  XXL = 1439,
  XXXL = 1679,
  XXXXL = 1919,
  MIN = 0,
  MAX = Infinity
}

@customElement(`${prefix}-breakpoint`)
export class Breakpoint extends LitElement {
  public static styles = styles;
  private viewportObservable: ViewportObservable;
  private wrapperHtml = '';

  @query('.wrapper')
  private wrapper: HTMLElement;

  @property({type: Boolean})
  private visible = false;


  // @ts-ignore
  @property({ converter: (val: string) => {
    if (val.includes('-')) {
      const values = val.split('-') as [ string, string ];
      const minVal = values[0].toUpperCase() as WidthKey;
      const maxVal = values[1].toUpperCase() as WidthKey;

      return [ BreakpointWidth[minVal], BreakpointWidth[maxVal] ];
    } else {
      return BreakpointWidth[val.toUpperCase() as WidthKey];
    }
  }})
  private width: number | [ number, number ];

  public async connectedCallback() {
    super.connectedCallback();

    setTimeout(async () => {
      this.viewportObservable = ViewportObserver.changed((state) => {
        this.checkIsVisibile(state);
      });
      this.checkIsVisibile(await ViewportObserver.state);
    });
  }

  public disconnectedCallback() {
    super.disconnectedCallback();
    if (this.viewportObservable) {
      this.viewportObservable.remove();
    }
  }

  private checkIsVisibile(size: Size) {
    const bpWidth = this.width;
    const vpWidth = size.width;
    const minW = bpWidth instanceof Array ? bpWidth[0] : bpWidth;
    const maxW = bpWidth instanceof Array ? bpWidth[1] : BreakpointWidth.MAX;

    this.visible = vpWidth > minW && vpWidth <= maxW;
  }

  public render() {
    const { visible } = this;
    return html`
      <span class="wrapper">
        ${visible ? html`<slot></slot>` : ''}
      </span>
    `;
  }
}
