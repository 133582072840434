import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-section-title`)
export class SectionTitle extends LitElement {
  public static styles = styles;

  @property({type: Boolean})
  private center = false;

  public updated() {
    this.classList.toggle('center', this.center);
  }

  public render() {
    return html`
      <djx-text lh-xs size="xl">
        <slot name="title"></slot>
      </djx-text>
      <djx-text lh-xs size="xl" type='secondary'>
        <slot name="subtitle"></slot>
      </djx-text>
    `;
  }
}
