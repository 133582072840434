import {LitElement, html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';
import { PointerSwiper } from '../../../slider/pointer-swiper';
import { clamp } from '../../../utils/math';
import { TweenMax } from 'gsap';

@customElement(`${prefix}-page-slider-pagination`)
export class PageSliderPagination extends LitElement {
  public static styles = styles;
  private binded = false;
  private dragging = false;
  private progress = 0;

  @query('.progress__handle')
  private handleElement: HTMLElement;

  @query('.progress__block')
  private blockElement: HTMLElement;

  @query('.progress')
  private progressElement: HTMLElement;

  @property({ type: String })
  private type = '';

  @property({ type: String })
  private color = '';

  @property({type: Number})
  private count = 0;

  @property({type: Number, reflect: true})
  private index = 0;

  updated() {
    this.classList.toggle('wide', this.type === 'wide');
    this.classList.toggle('compact', this.type === 'compact');

    if (this.type === 'wide') {
      this.bindDrag();
    }
  }

  get items() {
    const { count, index } = this;
    let result = [];
    for (let i = 0; i < count; i++) {
      const activeClass = i === index ? 'list__item--active' : '';
      result.push(html`
        <li data-index="${i}" class="list__item ${activeClass}"></li>
      `);
    }
    return result;
  }

  attributeChangedCallback(name: string, oldVal: any, newVal: any) {
    if (!this.dragging && name === 'index' && this.type === 'wide') {
      this.setToIndex(newVal);
    }
    super.attributeChangedCallback(name, oldVal, newVal);
  }

  updateIndexFromProgress() {
    const max = this.count;
    this.index = clamp(Math.floor(this.progress * max), 0, max - 1);
  }

  updateDragProgress() {
    const { left: leftProgress, width: progressWidth } = this.progressElement.getBoundingClientRect();
    const { left: leftHandle, width: handleWidth } = this.handleElement.getBoundingClientRect();
    const x = leftHandle - leftProgress;
    this.progress = clamp(x / (progressWidth - handleWidth), 0, 1);
  }

  setToIndex(index: number) {
    if (!this.progressElement || !this.blockElement) return;
    const { width } = this.progressElement.getBoundingClientRect();
    const { width: handleWidth } = this.blockElement.getBoundingClientRect();
    const x = (width / (this.count - 1)) * index - handleWidth / 2
    this.setHandlePosition(x, true);
  }

  bindDrag() {
    if (!this.binded) {
      this.binded = true;

      const dragbar = new PointerSwiper(this.handleElement, {});
      let currentX = 0;
      let lastX = 0;

      dragbar.on('down', () => {
        const { left: leftProgress } = this.progressElement.getBoundingClientRect();
        const { left: leftHandle } = this.handleElement.getBoundingClientRect();
        lastX = leftHandle - leftProgress;
        this.dragging = true;
      })

      dragbar.on('drag', () => {
        let [x, _] = dragbar.deltaPosition;
        currentX = x + lastX;
        this.setHandlePosition(currentX);
        this.updateDragProgress();
        this.updateIndexFromProgress();
        this.dispatchEvent(new CustomEvent('dragupdate', {
          detail: {
            progress: this.progress
          }
        }))
      });

      dragbar.on('up', () => {
        this.dragging = false;
      });
    }
  }

  setHandlePosition(x: number, animate: boolean = false) {
    const { width: progressWidth } = this.progressElement.getBoundingClientRect();
    const { width: handleWidth } = this.blockElement.getBoundingClientRect();
    x = clamp(x, 0, progressWidth - handleWidth);
    TweenMax.to(this.blockElement, animate ? 1 : 0.15, { x, ease: 'Power3.out' });
  }

  get inlineStyle() {
    const { color } = this;
    return html`
      <style>
        .progress:before {
          background-color: ${color} !important;
        }
        .progress__mark {
          background-color: ${color} !important;
        }
      </style>
    `;
  }

  get compactTemplate() {
    return html`
      <ul class="list">
        ${this.items}
      </ul>
    `;
  }

  get wideTemplate() {
    return html`
      ${this.inlineStyle}
      <div class="progress">
        <div class="progress__block">
          <div class="progress__handle"></div>
          <djx-text size="xs" class="progress__text">
            ${this.index + 1} / ${this.count}
          </djx-text>
          <div class="progress__mark"></div>
        </div>
      </div>
    `;
  }

  public render() {
    const { type, wideTemplate, compactTemplate } = this;
    return type === 'wide' ? wideTemplate : compactTemplate;
  }
}
