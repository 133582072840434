import {LitElement, html} from 'lit';
import {property, customElement, state} from 'lit/decorators.js';
import ClassBreakpoints from '../../../utils/class-breakpoints';

import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-project-hero`)
export class ProjectHero extends LitElement {
  public static styles = styles;
  private breakpoints: ClassBreakpoints;

  @state()
  private isDesktop = false;

  @property({ type: String })
  private bgimage = '';

  @property({type: String})
  private overlaycolor = 'transparent';

  @property({type: String})
  private textcolor = 'black';

  public connectedCallback() {
    super.connectedCallback();
    this.breakpoints = new ClassBreakpoints(this, {
      'mobile': { max: 768 },
      'desktop': { min: 768 },
    }, this.onBreakpointChange.bind(this));
  }

  private onBreakpointChange(name: string) {
    this.isDesktop = name === 'desktop';
  }

  get containerStyle() {
    return html`
      <style>
        .background:after { background-color: ${this.overlaycolor}; }
      </style>
    `;
  }

  get textResponsiveColor() {
    return this.isDesktop ? this.textcolor : 'black';
  }

  public render() {
    const { containerStyle } = this;
    return html`
       ${containerStyle}
      <div class="container">
        <div class="content">
          <djx-spacer size="lg"></djx-spacer>
          <djx-text-transition selector="h1,h2" mode="words" class="center">
            <djx-text xs="xxxl" md="xxl" weight="medium" lh-sm color="${this.textResponsiveColor}">
              <slot name="title"></slot>
            </djx-text>
            <djx-text size="lg" weight="light" type='secondary' center lh-sm color="${this.textResponsiveColor}">
              <slot name="subtitle"></slot>
            </djx-text>
          </djx-text-transition>
          <djx-spacer size="sm"></djx-spacer>
        </div>
      </div>
      <div class="background">
        <djx-image cover full-size parallax src="${this.bgimage}">
        </djx-image>
        <div class="button">
          <slot name="button"></slot>
        </div>
      </div>
    `;
  }
}
