import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-header`)
export class Header extends LitElement {
  public static styles = styles;

  @property()
  private url: string;

  @property()
  private logourl: string;

  public render() {
    const { url, logourl } = this;
    return html`
    <djx-grid>
      <djx-grid-row>
        <djx-grid-col xs="4">
          <djx-router-link>
            <djx-transition preset="from-above" selector="a" delay="1.5" >
              <a href="${url}" class="logo">
                <img src="${logourl}"/>
              </a>
            </djx-transition>
          </djx-router-link>
        </djx-grid-col>
        <djx-grid-col xs="20" class="desktop-col">
            <slot name="desktop-menu"></slot>
        </djx-grid-col>
        <djx-grid-col xs="20" class="mobile-col">
          <slot name="mobile-menu"></slot>
        </djx-grid-col>
      </djx-grid-row>

    </djx-grid>
    `;
  }
}
