import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import prefix from '../prefix';

import styles from './overlay.scss';
@customElement(`${prefix}-router-overlay`)
export class RouterOverlay extends LitElement {

  public static styles = styles;

  public render() {
    return html`<slot></slot>`;
  }
}
