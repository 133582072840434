import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import { ItemsFilter, FiltereableItem } from '../../../filter/items-filter';

import prefix from '../prefix';
import styles from './jobs-list.scss';

@customElement(`${prefix}-jobs-list`)
export class JobsList extends LitElement {
  public static styles = styles;
  private filter: ItemsFilter;

  constructor() {
    super();
    this.filter = new ItemsFilter({
      maxItemsPerPage: Infinity,
      incrementSize: 0,
      onAfterFilterUpdate: () => {},
    });
  }

  private handleFilterChange(event: CustomEvent) {
    const { filter } = this;
    filter.resetMaxItems();
    if (event.detail.filter === 'all') {
      filter.allCategories();
    } else {
      filter.clearCategories();
      filter.addCategoryFilter(event.detail.filter);
    }
    filter.update();
  }

  private resetItems() {
    const { filter } = this;
    filter.resetMaxItems();
    filter.allCategories();
    filter.clearSlugs();
    filter.update();
  }

  private registerItem(event: CustomEvent) {
    const target = event.target as FiltereableItem;
    this.filter.registerItem(target);
    this.filter.registerCategory(target.catid);
    this.resetItems();
  }

  public render() {
    return html`
    <djx-grid>
      <djx-grid-row>
        <djx-grid-col xs="24" lg="[9,1]">
          <slot name="title"></slot>
        </djx-grid-col>
      </djx-grid-row>
      <djx-grid-row>
        <djx-grid-col xs="24" lg="[22,2]">
          <slot name="filter" @filterchange="${this.handleFilterChange}"></slot>
        </djx-grid-col>
      </djx-grid-row>
      <djx-grid-row>
        <djx-grid-col xs="24" lg="[20,2]">
          <djx-spacer size="md"></djx-spacer>
          <slot @jobsitemregister="${this.registerItem}"></slot>
        </djx-grid-col>
      </djx-grid-row>
    </djx-grid>
    <djx-spacer size="lg"></djx-spacer>
    `;
  }
}
