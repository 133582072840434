import {LitElement, html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';
import { loadMap } from '../../../google-maps';
import prefix from '../prefix';
import styles from './index.scss';

// @ts-ignore should enable--resolveJsonModule on TS
import mapStyle from './style.json';

@customElement(`${prefix}-page-map`)
export class PageMap extends LitElement {

  @query("#map")
  private map: HTMLElement;

  public static styles = styles;
  private marker: any;
  private instance: any;

  @property({ type: Number, reflect: true })
  private lat = 49.0159405;

  @property({ type: Number, reflect: true })
  private lng = 8.3394944;

  @property({ type: Number, reflect: true })
  private zoom = 13;

  @property({ type: String })
  private icon = '';

  @property({ type: String })
  private key = '';

  connectedCallback() {
    super.connectedCallback();
    this.initMap();
  }

  private async initMap() {
    if (this.loaded) return false;
    await loadMap(this.key);
    this.instance = new (window as any).google.maps.Map(this.map, {
      center: { lat: this.lat, lng: this.lng },
      styles: mapStyle,
      zoom: this.zoom,
      disableDefaultUI: true,
    });
    requestAnimationFrame(() => {
      this.updateMarker();
      this.updateMapPosition();
    });
  }

  private updateMarker() {
    if (!this.loaded) return false;
    if (!this.marker) {

      const icon = {
        url: this.icon,
        scaledSize: new this.gmaps.Size(88, 136),
        origin: new this.gmaps.Point(0, 0),
      };

      this.marker = new this.gmaps.Marker({
        map: this.instance,
        icon,
      });
    }
    this.marker.setPosition({
      lat: this.lat,
      lng: this.lng,
    });
  }

  private updateMapPosition() {
    if (!this.marker) return false;
    this.instance.setCenter(this.marker.position);
  }

  public updated() {
    if (!this.loaded) return false;
    this.updateMarker();
    this.updateMapPosition();
  }

  get gmaps() {
    return (window as any).google.maps;
  }

  get loaded() {
    return (this.instance) !== undefined;
  }

  public render() {
    return html`
      <div id="map"></div>
    `;
  }
}
