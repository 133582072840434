import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-home-brief`)
export class HomeBrief extends LitElement {
  public static styles = styles;

  public render() {
    return html`
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="24" md="[18, 3]">
              <djx-text size="mdd" weight="light" lh-md two-column-lg block>
                <slot></slot>
                <div class="glyph">
                  <slot name="glyph"></slot>
                </div>
              </djx-text>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
    `;
  }
}
