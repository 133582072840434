import {LitElement, html} from 'lit';
import {property, customElement, queryAssignedNodes} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './area-project-list-item.scss';

@customElement(`${prefix}-area-project-list-item`)
export class AreaProjectListItem extends LitElement {
  public static styles = styles;

  @queryAssignedNodes('description')
  private description: NodeListOf<HTMLElement>;

  @property({ type: String })
  private imagesrc = '';

  @property({ type: String })
  private color = '#ff0000';

  private handleMouseEnter(event: MouseEvent) {
    this.dispatchEvent(new CustomEvent('itementer', {
      bubbles: true,
      detail: {
        src: this.imagesrc,
      }
    }))
  }

  updated() {
    const { description } = this;
    const visible = description.length > 0;
    this.classList.toggle('show-description', visible)
  }

  firstUpdated() {
    setTimeout(() => {
      this.dispatchEvent(new CustomEvent('registeritem', {
        bubbles: true,
        detail: {
          src: this.imagesrc
        }
      }));
    })
  }

  get inlineStyle() {
    return html`
      <style>
        .hover-solid-background {
          background-color: ${this.color};
        }
      </style>
    `;
  }

  public render() {
    const { color, inlineStyle, imagesrc } = this;
    return html`
      ${inlineStyle}
      <div class="hover-solid-background"></div>
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="[22, 1]" class="line-col"></djx-grid-col>
        </djx-grid-row>
      </djx-grid>

      <djx-grid class="max-width main-grid"
        @mouseenter="${this.handleMouseEnter}"
      >
        <djx-grid-row class="image-row">
          <djx-grid-col xs="24">
            <djx-image cover src="${imagesrc}"></djx-image>
          </djx-grid-col>
        </djx-grid-row>
        <djx-grid-row class="content-row">
          <djx-grid-col xs="24" lg="[8, 2]">
            <djx-text size="lgl" lh-sm weight="medium" style="pointer-events: none;">
              <slot name="title"></slot>
              <div class="description">
                <slot name="description"></slot>
              </div>
            </djx-text>
          </djx-grid-col>
          <djx-grid-col xs="24" lg="[6, 1]">
            <djx-text size="lgll" lh-sm>
              <slot name="location"></slot>
            </djx-text>
          </djx-grid-col>
          <djx-grid-col xs="24" lg="[4, 1]" xl="[3, 2]" class="link-col">
            <div class="link">
              <djx-router-link>
                <djx-text size="sm"
                  .color="${color}"
                  lh-sm
                  no-wrap
                  uppercase
                  underline
                  underlineboldhover
                >
                  <slot name="url"></slot>
                </djx-text>
              </djx-router-link>
            </div>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
    `;
  }
}
