import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';

import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-area-featured`)
export class AreaFeatured extends LitElement {
  public static styles = styles;

  public render() {
    return html`
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="24" lg="[23, 1]">
            <slot name="title"></slot>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
      <slot name="items"></slot>
    `;
  }
}
