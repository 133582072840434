import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

@customElement(`${prefix}-home-hero`)
export class HomeHero extends LitElement {
  public static styles = styles;

  @property({ type: String })
  private overlaycolor = 'transparent';

  @property({ type: String})
  private bgimage = '';

  get containerStyle() {
    return html`
      <style>
        .background:after { background-color: ${this.overlaycolor}; }
      </style>
    `;
  }

  public render() {
    const { containerStyle } = this;
    return html`
      ${containerStyle}
      <djx-transition preset="fade-in" selector=".background" delay="0.25">
      <div class="container">
        <div class="content">
          <djx-spacer size="lg"></djx-spacer>
          <djx-text-transition selector='h1,h2' mode="words"  class="center">
            <djx-text xs="xxxl" md="xxl" white weight="medium" lh-sm block>
              <slot name="title"></slot>
            </djx-text>
            <djx-text size="lg" weight="light" type='secondary' white lh-sm>
              <slot name="subtitle"></slot>
            </djx-text>
          </djx-text-transition>
          <djx-spacer size="sm"></djx-spacer>
          <slot name="button"></slot>
        </div>
        <div class="background">
            <djx-image cover full-size zoom zoom-in src="${this.bgimage}"></djx-image>
        </div>
      </div>
      </djx-transition>
    `;
  }
}
