import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import scroller from '../../../scroller';
import { listenCompose, listenEl, Unlisten } from '@smoovy/event';
import { Route } from '@smoovy/router';
import { parseUrl } from '@smoovy/utils';
import { QueryDeep } from '../../../utils/query-deep';
import prefix from '../prefix';
import { RouterOutlet } from './outlet';

import styles from './link.scss';

@customElement(`${prefix}-router-link`)
export class RouterLink extends LitElement {
  public static styles = styles;
  private outlet: RouterOutlet;
  private link: HTMLAnchorElement;
  private unlisten: Unlisten;
  private route: Route;
  private binded: Boolean = false;

  constructor() {
    super();
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseClick = this.onMouseClick.bind(this);
  }

  public connectedCallback() {
    super.connectedCallback();
    this.bind();
  }

  public navigate() {
    this.bind();
    const from = parseUrl(window.location.href);
    const to = parseUrl(this.link.href);

    // update hash position
    if (!!to.hash) {
      /*
        FIX: This will throw an exception because by changing the hash manually
        we are triggering an event on history state inside the router.
      */
      // window.location.hash = to.hash;
      scroller.scrollToHash(to.hash);
    }

    // abort further navigation if target href is the same
    if (from.host === to.host && from.pathname === to.pathname) {
      return;
    }

    this.outlet.router?.navigate(this.route);
  }

  private onMouseClick(event: MouseEvent) {
    event.preventDefault();
    this.navigate();
  }

  private onMouseEnter(event: MouseEvent) {
    try {
      this.outlet.router?.preload(this.route);
    } catch(e) {
      console.log(e);
    }

  }

  shouldBind() {
    const { link, outlet, binded } = this;
    const validLink = link !== undefined && link.target !== '_blank';
    return (outlet !== undefined && validLink && link.href !== undefined && !binded);
  }

  private getLink(): HTMLAnchorElement {
    const link = QueryDeep(this, 'a')[0] as HTMLAnchorElement;
    return link;
  }

  public updateSelectors() {
    this.link = this.getLink();
    this.outlet = document.body.querySelector(
      `${prefix}-router-outlet`
    ) as RouterOutlet;
  }

  public bind() {
    this.updateSelectors();
    if (!this.shouldBind()) return;

    const url = parseUrl(this.link.href);

    this.route = {
      url: `${url.pathname}${url.search}${url.hash}`,
      load: this.link.href
    };

    this.unlisten = listenCompose(
      listenEl(this, 'click', this.onMouseClick),
      listenEl(this.link, 'mouseenter', this.onMouseEnter)
    );
    this.binded = true;
  }


  get isExternal() {
    const { link } = this;
    return link && link.target === '_blank';
  }

  get url() {
    const { link } = this;
    return link ? link.href : '';
  }

  public disconnectedCallback() {
    if (this.binded) {
      this.unlisten();
      this.binded = false;
    }
  }

  public render() {
    return html`<slot></slot>`;
  }
}
