import {LitElement, html} from 'lit';
import {property, customElement, queryAssignedNodes} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './home-areas.scss';

@customElement(`${prefix}-home-areas`)
export class HomeAreas extends LitElement {
  public static styles = styles;
  private images: any[] = [];

  @queryAssignedNodes('items')
  @property()
  private items= [];

  @property({type: String})
  private activeid = '';

  // Populate images with all child elements inside items slot
  private handleItemSlotChange() {
    const { items } = this;
    items.forEach((el: any) => {
      this.images.push({
        id: el.id,
        src: el.bgimage,
        color: el.color
      });
    });
    this.requestUpdate();
  }

  get backgrounds() {
    const { images, activeid, defaultId } = this;
    let id = activeid || defaultId;
    return images.map(img => {
      const classes = ['container__background'];
      if (img.id === id) {
        classes.push('container__background--active');
      }
      return html`
        <div class="${classes.join(' ')}">
          <djx-image class="container__image" parallax src="${img.src}"></djx-image>
        </div>
      `
    });
  }

  get overlay() {
    const { images, activeid, defaultId } = this;
    let id = activeid || defaultId;
    const img = images.find(img => img.id === id);
    const overlayColor = img ? img.color : 'red';
    return html`
      <div class="container__overlay" style="background-color:${overlayColor}"></div>
    `;
  }

  private handleItemOver(event: CustomEvent) {
    const id = (event.target as HTMLElement).id;
    this.activeid = id;
    const { items } = this;
    // remove the static attribute to preselect an item
    items.forEach((el: any) => {
      if (id === el.id) {
        el.setAttribute('selected', true);
      } else {
        el.removeAttribute('selected');
      }

    });
  }

  get defaultId() {
    const { images } = this;
    if (images.length > 0) {
      return images[0].id;
    }
    return '';
  }

  public render() {
    const { backgrounds, overlay } = this;
    return html`
      <div class="container">
        ${overlay}
        ${backgrounds}
        <djx-grid>
          <djx-grid-row class="center-xs">
            <djx-grid-col xs="24">
                <slot name="title"></slot>
                <djx-spacer size="sm"></djx-spacer>
            </djx-grid-col>
            <djx-grid-col xs="24">
              <ul class="list">
                <slot
                  name="items"
                  @slotchange="${this.handleItemSlotChange}"
                  @areaitemover="${this.handleItemOver}"
                ></slot>
              </ul>
            </djx-grid-col>
          </djx-grid-row>
        </djx-grid>
      </div>
    `;
  }
}
