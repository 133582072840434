import { Browser } from "@smoovy/utils";
export const isNative = () => Browser.mobile || window.innerWidth <= 768;

import ResizeObserver from 'resize-observer-polyfill';
import { lerp, fixedDecimal } from './utils/math';
import { EventEmitter } from '@smoovy/event';
import { requestFrame, cancelFrame } from './utils/global-raf';

const PREFIX = 'custom-scroller';

const CSS_STYLE = `
  .${PREFIX}-container { position: fixed; width: 100%; top: 0; will-change: transform; };
  .${PREFIX}-expander { height: 100%; }
  .${PREFIX}-expander:after { display: block; content: ''; height: var(--scroll-height); }
`;

class Scroller extends EventEmitter {

  private dom: any;
  private firstUpdate: boolean;
  private damping: number;
  private tail: number;
  private lastTail: number;
  private smoothEnabled: boolean;
  private observer: ResizeObserver;
  private rafHandle: number;

  constructor(target: HTMLElement, damping = 0.1) {
    super();
    this.dom = {
      style: document.createElement('style'),
      // wrapper: document.createElement('div'),
      container: document.createElement('div'),
      expander: document.createElement('div'),
      parent: target.parentElement,
      target,
    }

    this.smoothEnabled = false;
    this.firstUpdate = true;
    this.damping = damping;
    this.tail = 0;
    this.lastTail = 0;
    this.observer = new ResizeObserver(this.onResize);
    this.wrap();
    this.start();
  }

  wrap() {
    const { target, parent, wrapper, container, expander, style } = this.dom;
    // wrapper.classList.add(`${PREFIX}-wrapper`);
    container.classList.add(`${PREFIX}-container`);
    expander.classList.add(`${PREFIX}-expander`);
    parent.appendChild(expander);
    parent.appendChild(container);
    container.appendChild(target);
    //parent.appendChild(wrapper);
    style.innerHTML = CSS_STYLE;
    document.head.appendChild(style);
  }

  unwrap() {
    // TODO
  }

  start() {
    const { observer, dom } = this;
    observer.observe(dom.target);
    this.onUpdate();
  }

  stop() {
    const { observer, dom } = this;
    observer.unobserve(dom.target)
    cancelAnimationFrame(this.rafHandle);
  }

  scrollToHash(hash: string, instant: boolean = false) {
      hash = hash.replace('#', '');
      const anchor = document.body.querySelector(`a[name=${hash}]`);
      console.log(anchor, hash);
      if (anchor) {
        const rect = anchor?.getBoundingClientRect();
        const y = rect.top + this.tail;
        this.scrollTo(y, instant);
      }
  }

  scrollTo(y: number, instant: boolean) {
    if (instant) {
      this.lastTail = y - 1;
      this.tail = y - 1;
    }
    window.scrollTo(0, y);
    this.onUpdate();
  }

  onResize = () => {
    const { dom } = this;
    const { height } = dom.container.getBoundingClientRect();
    dom.expander.style.setProperty('--scroll-height', `${height}px`);
    this.smoothEnabled = !isNative();
  }

  onScroll() {
    const { dom, tail } = this;
    dom.container.style.transform =`translateY(${-tail}px)`;
    this.emit('scroll', tail);
  }

  onUpdate = () => {
    const { damping } = this;
    const head = window.scrollY || window.pageYOffset;
    this.lastTail = this.tail;

    if (this.smoothEnabled) {
      this.tail = lerp(this.tail, head, damping);
      this.tail = fixedDecimal(this.tail, 2);
    } else {
      this.tail = head;
    }

    if (this.lastTail !== this.tail) {
      if (this.firstUpdate) {
        this.firstUpdate = false;
        this.tail = head;
      }
      this.onScroll();
    }
    requestFrame(this.onUpdate);
  }

  get scrollY() {
    return this.tail;
  }
}

const element = document.getElementById('js-page-wrapper') as HTMLElement;
const scroller = new Scroller(element, 0.075);
export default scroller;
