import { TweenMax } from 'gsap';
import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';
import ClassBreakpoints from '../../../utils/class-breakpoints';

@customElement(`${prefix}-tenantdownload-card`)
export class TenantDownloadCard extends LitElement {
  public static styles = styles;
  private breakpoints: ClassBreakpoints;

  @property({type: Boolean, reflect: true})
  private visible = true;

  @property({type: Boolean})
  private fullwidth = false;

  @property({type: Boolean})
  private transitionin = false;

  @property({type: String})
  private catname = '';

  @property({type: String})
  private catid = '';

  @property({type: String, reflect: true})
  private slug: '';

  @property({type: String})
  private bgimage: '';

  @property({type: String})
  private date = '';

  @property({type: String})
  private overlaycolor = '#ffffff';

  public connectedCallback() {
    super.connectedCallback();
    this.breakpoints = new ClassBreakpoints(this, {
      'mobile': { max: 1024 },
      'desktop': { min: 1024 },
    });
    this.notifyRegister();
  }

  updated() {
    this.classList.toggle('visible', this.visible);
    this.classList.toggle('fullwidth', this.fullwidth);
    if (!this.visible && this.transitionin) {
      TweenMax.to(this, 0, { opacity: 0 });
    }
  }

  public disconnectedCallback() {
    super.disconnectedCallback();
    this.breakpoints.unbind();
  }

  private notifyRegister() {
    requestAnimationFrame(() => {
      this.dispatchEvent(new CustomEvent('tenantdownloaditemregister', {
        bubbles: true,
        composed: true,
      }));
    });
  }

  handleBackgroundClick() {
    if (this.classList.contains('mobile')) {
      if (this.linkButton) {
        (this.linkButton as any).navigate();
      }
    }
  }

  handleMouseEnter() {
    // @ts-ignore
    this.linkButton!.transitionIn();
  }

  handleMouseLeave() {
    // @ts-ignore
    this.linkButton!.reset();
  }

  hexToRgb(hex: any) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  private onEnter() {
    if (this.visible && this.style.opacity === '0') {
      TweenMax.fromTo(this, 0.8, { opacity: 0, y: 50 }, { opacity: 1, y: 0 });
    }
  }

  get linkButton() {
    return this.querySelector('app-link-button');
  }

  get dateCategory() {
    const { date, catname } = this;
    return html`
      <div> ${date} | ${catname} </div>
    `;
  }

  get containerStyle() {
    return html`
      <style>
        .fullwidth-card {
          background-color: ${this.overlaycolor};
        }
        .card-inner:hover .background:after,
        .background:hover:after {
          background-color: ${this.overlaycolor};
          opacity: 0.5;
        }
      </style>
    `;
  }

  // get fullwidthCard() {
  //   const { bgimage, dateCategory, containerStyle } = this;
  //   return html`
  //     ${containerStyle}
  //     <djx-grid class="fullwidth-card">
  //       <djx-grid-row>
  //         <djx-grid-col xs="16" class="fullwidth-card__col">
  //           <djx-image cover full-size parallax src="${bgimage}">
  //           </djx-image>
  //         </djx-grid-col>
  //         <djx-grid-col xs="8">
  //           <djx-intersect .enabled="${this.transitionin}" @enter="${this.onEnter}">
  //             <div class="wrapper">
  //               <div class="title">
  //                 <djx-text size="mdl" lh-sm white>
  //                   <slot></slot>
  //                 </djx-text>
  //                 <djx-text size="xxs" lh-md white>
  //                   <slot></slot>
  //                 </djx-text>
  //                 <div class="fullwidth-card_button">
  //                   <slot name="button"></slot>
  //                 </div>
  //               </div>
  //             </div>
  //           </djx-intersect>
  //         </djx-grid-col>
  //       </djx-grid-row>
  //   </djx-grid>
  //   `
  // }

  get normalCard() {
    const { bgimage, dateCategory, containerStyle } = this;
    const bgColorRGB = this.hexToRgb(this.overlaycolor);
    return html`
        ${containerStyle}
        <div class="card-inner"
          @mouseenter="${this.handleMouseEnter}"
          @mouseleave="${this.handleMouseLeave}"
          @click="${this.handleBackgroundClick}"
        >
          <div class="image-wrapper">
            <djx-image
              cover
              full-width
              parallax
              nopreload
              src="${bgimage}"
              class="background"
            >
              <div class="button">
                <slot name="button"></slot>
              </div>
            </djx-image>
          </div>
          <djx-intersect .enabled="${this.transitionin}" @enter="${this.onEnter}">
            <div class="wrapper" style="background-color: rgba(${bgColorRGB?.r}, ${bgColorRGB?.g}, ${bgColorRGB?.b} , 0.3)">
              <div class="title">
                <djx-text size="mdl" lh-sm black>
                  <slot></slot>
                </djx-text>
                <djx-text size="xxs" lh-md black>
                  <slot name="subline"></slot>
                </djx-text>
              </div>
            </div>
          </djx-intersect>
        </div>
    `
  }

  public render() {
    const { fullwidth, fullwidthCard, normalCard } = this as any;
    return fullwidth ? fullwidthCard : normalCard;
  }
}
