import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './news-content-block.scss';

@customElement(`${prefix}-news-content-block`)
export class NewsContentBlock extends LitElement {
  public static styles = styles;

  @property({ type: String })
  private type = 'text';

  firstUpdated() {
    const { type } = this;
    this.classList.toggle('text-content', type === 'text');
    this.classList.toggle('video-content', type === 'video');
    this.classList.toggle('image-content', type === 'image');
    this.classList.toggle('slider-content', type === 'slider');
  }

  public render() {
    const colSize = this.type === 'text' ? '[16, 4]' : '[20, 2]';
    return html`
      <djx-grid>
        <djx-grid-row>
          <djx-grid-col xs="24" lg="${colSize}">
            <slot></slot>
          </djx-grid-col>
        </djx-grid-row>
      </djx-grid>
    `;
  }
}
