import {LitElement, html} from 'lit';
import {property, customElement, queryAssignedNodes, state} from 'lit/decorators.js';
import { debounce } from '../../../utils/debounce';
import { ItemsFilter, FiltereableItem } from '../../../filter/items-filter';
import prefix from '../prefix';
import styles from './index.scss';

/*
  TODO: On query selected filter should be  (all)
  TODO: Search hint to execute query on input box
*/

const INITIAL_VISIBLE_ITEMS = 7;
const VISIBLE_ITEMS_STEP = 4;
const MIN_TIME_SEARCH = 1000;

@customElement(`${prefix}-news-filter-search`)
export class NewsFilterSearch extends LitElement {
  public static styles = styles;
  private filter: ItemsFilter;
  @state()
  private searching = false;

  @state()
  private noresults = false;

  @state()
  private loaded = false;

  @property({type: String})
  private endpointurl = '';

  @queryAssignedNodes('items')
  public itemNodes: HTMLElement[];

  constructor() {
    super();
    this.loadMoreItems = debounce(this.loadMoreItems, 100, false);
    this.onAfterFilterUpdate = this.onAfterFilterUpdate.bind(this);
    this.filter = new ItemsFilter({
      maxItemsPerPage: INITIAL_VISIBLE_ITEMS,
      incrementSize: VISIBLE_ITEMS_STEP,
      onAfterFilterUpdate: this.onAfterFilterUpdate,
    });
    document.addEventListener('loaded', this.populateLinks );
  }

  private populateLinks() {
    const newsCards = this.querySelectorAll('app-news-card');
    newsCards.forEach(el => {
      let url = el.getAttribute('siteurl');
      let caption = el.getAttribute('viewdetailcaption');
      let button = el.querySelector('app-link-button');
      if (button !== null)button.innerHTML = `<a href='${url}'>${caption}</a>`;
    });
  }

  private resetItems() {
    const { filter } = this;
    this.noresults = false;
    this.searching = false;
    filter.resetMaxItems();
    filter.allCategories();
    filter.clearSlugs();
    filter.update();
  }

  private handleFilterChange(event: CustomEvent) {
    const { filter } = this;
    this.hideAll();
    setTimeout( ()=> {
      filter.resetMaxItems();
      if (event.detail.filter === 'all') {
        filter.allCategories();
        filter.update();
      } else {
        filter.clearCategories();
        filter.addCategoryFilter(event.detail.filter);
      }
      filter.update();
    }, 100);
  }

  private hideAll() {
    // force to hide all items
    const { filter } = this;
    filter.registerCategory('none');
    filter.clearCategories();
    filter.addCategoryFilter('none');
    filter.update();
  }

  private onAfterFilterUpdate() {
    this.loaded = true;
  }

  private loadMoreItems() {
    this.filter.incrementItemsFeed();
    this.filter.update();
  }

  private async sleep(time: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }

  private async getSearchResults(query: string) {
    this.searching = true;
    let timeStart = performance.now();
    const response = await fetch(`${this.endpointurl}?q="${query}"`);
    const json = await response.json();
    const timeDelta = performance.now() - timeStart;
    await this.sleep(Math.max(0, MIN_TIME_SEARCH - timeDelta));
    this.searching = false;
    return json.data;
  }

  private async handleSearch(event: CustomEvent) {
    const { query } = event.detail;
    const { filter } = this;

    this.resetItems();
    // abort filtering, showing all items
    if (query.trim() === '') return;

    // Clear all categories and slugs to hide all items
    filter.clearCategories();
    filter.clearSlugs();
    filter.update();

    // Search
    this.loaded = false;
    const results = await this.getSearchResults(query);
    if (results && results.length > 0) {
      /*
        Configure filter to show only the items matching the
        slugs in the results.
      */
      results.forEach((result: any) => {
        filter.addSlugFilter(result.slug)
      });
      // Reset to all filter
      filter.allCategories();
      filter.update();
    } else {
      this.noresults = true;
    }
  }

  private registerItem(event: CustomEvent) {
    const target = event.target as FiltereableItem;
    this.filter.registerItem(target);
    this.filter.registerCategory(target.catid);
    this.resetItems();
  }

  public render() {
    const { noresults, searching } = this;
    return html`
    <djx-grid>
      <djx-grid-row class="row">
        <djx-grid-col xs="24">
          <djx-breakpoint width="min-md">
            <djx-spacer size="md"></djx-spacer>
          </djx-breakpoint>
        </djx-grid-col>
        <djx-grid-col xs="24" lg="5" class="search-col">
          <slot name="search" @searchenter="${this.handleSearch}"></slot>
        </djx-grid-col>
        <djx-grid-col xs="24" lg="19">
          <slot name="filter" @filterchange=${this.handleFilterChange}></slot>
        </djx-grid-col>
      </djx-grid-row>
    </djx-grid>
    <djx-grid>
      <djx-grid-row>
        <djx-grid-col xs="24">
          <djx-spacer size="sm"></djx-spacer>
        </djx-grid-col>
      </djx-grid-row>
      <djx-grid-row>
          <slot
            name="featured"
            @newsitemregister=${this.registerItem}
          ></slot>
      </djx-grid-row>
      <djx-grid-row class="gap-15">
        <slot
          name="items"
          @newsitemregister=${this.registerItem}
        ></slot>
        <djx-intersect
          enabled=${this.loaded}
          @enter="${this.loadMoreItems}"
        ></djx-intersect>
      </djx-grid-row>
      <djx-grid-row>
        <djx-grid-col xs="24" class="center-xs">
          <djx-toggler .visible="${searching}">
              <djx-spacer size="lg"></djx-spacer>
              <djx-spinner></djx-spinner>
              <slot name="searching-text"></slot>
              <djx-spacer size="lg"></djx-spacer>
          </djx-toggler>
        </djx-grid-col>
        <djx-grid-col xs="24" class="center-xs">
          <djx-toggler .visible="${noresults}">
            <djx-spacer size="lg"></djx-spacer>
            <slot name="no-results-text"></slot>
            <djx-spacer size="lg"></djx-spacer>
          </djx-toggler>
        </djx-grid-col>
      </djx-grid-row>
    </djx-grid>
    `;
  }
}
