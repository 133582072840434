import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './menu-desktop.scss';

@customElement(`${prefix}-menu-desktop`)
export class MenuDesktop extends LitElement {
  public static styles = styles;

  @property({ type: String })
  private color = '';

  private handleMouseEnter() {
    this.classList.add('overlay--active');
  }

  private handleMouseLeave() {
    this.classList.remove('overlay--active');
  }

  get inlineStyle() {
    return html`
      <style>
        :host(:not(.overlay--active)) {
          --text-color: ${this.color};
        }

        :host(:not(.overlay--active)) .end-xs {
          --text-color: ${this.color};
        }
      </style>
    `;
  }

  public render() {
    return html`
    ${this.inlineStyle}
    <div id="overlay" class="overlay"></div>
    <djx-transition preset="from-above" selector="app-menu-item, span" stagger="0.14" delay="1.45" >
      <djx-grid-row>
        <djx-grid-col xs="24" class="end-xs">
          <span>
            <slot name="copy"></slot>
          </span>
        </djx-grid-col>
      </djx-grid-row>
      <djx-grid-row>

          <djx-grid-col xs="[20,4]" class="menu-row">
            <slot
              @mouseenter="${this.handleMouseEnter}"
              @mouseleave="${this.handleMouseLeave}"
            ></slot>
          </djx-grid-col>

      </djx-grid-row>
    </djx-transition>
    `;
  }
}
