import { TweenMax, gsap } from 'gsap';
import {LitElement, html} from 'lit';
import {customElement, query} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './index.scss';

const NOOP = () => {}

@customElement(`${prefix}-page-transition`)
export class PageTransition extends LitElement {
  public static styles = styles;
  private queuedFunction = NOOP;

  @query('#overlay')
  private overlay: HTMLElement;

  @query('#overlay-1')
  private overlay1: HTMLElement;

  @query('#overlay-2')
  private overlay2: HTMLElement;

  transitionOut = async () => {
    if (this.isBusy) return this.queueTransition(this.transitionOut);
    return new Promise(resolve => {
      const from = {
        scaleY: 1,
        display: 'block',
        transformOrigin: 'bottom',
      };
      const to = {
        scaleY: 0,
        display: 'none',
        ease: 'Power4.easeOut',
        onStart: () => {
          document.body.classList.remove('loading');
          setTimeout(() => {
            document.dispatchEvent(new CustomEvent('loaded'));
          }, 500);
        },
        onComplete: () => {
          resolve(null);
          this.queuedFunction();
        },
        stagger: 0.25,
      };

      TweenMax.fromTo([this.overlay, this.overlay1, this.overlay2], 1.2, from, to);
    });
  }

  private async queueTransition(fn: Function) {
    return new Promise(resolve => {
      this.queuedFunction = async () => {
        this.queuedFunction = NOOP;
        await fn();
        resolve(null);
      }
    })
  }

  transitionIn = async () => {
    if (this.isBusy) return this.queueTransition(this.transitionIn);
    return new Promise(resolve => {
      TweenMax.fromTo([this.overlay1, this.overlay], 0.8, {
        scaleY: 0,
        transformOrigin: 'top',
        display: 'block',
      }, {
        scaleY: 1,
        stagger: 0.1,
        ease: 'Power4.easeOut',
        onComplete: () => {
          resolve(null);
          this.queuedFunction();
          document.body.classList.remove('loading');
        },
      })
    });
  }

  get isBusy() {
    const { overlay, overlay1, overlay2 } = this;
    return gsap.isTweening(overlay) || gsap.isTweening(overlay1) || gsap.isTweening(overlay2);
  }

  public render() {
    return html`
      <div class="overlay" id="overlay"></div>
      <div class="overlay-1" id="overlay-1"></div>
      <div class="overlay-2" id="overlay-2"></div>
    `;
  }
}
