import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import prefix from '../prefix';
import styles from './page-link-list.scss';

@customElement(`${prefix}-page-link-list`)
export class PageLinkList extends LitElement {
  public static styles = styles;

  public render() {
    return html`
      <djx-text size="f16" weight="medium" uppercase class="${this.hasSlotContent ? 'title': ''}">
        <slot name="title"></slot>
      </djx-text>
      <slot></slot>
    `;
  }

  public get hasSlotContent() {
    return !!this.querySelector('[slot="title"]');
  }
}
