import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';
import { Router, RouterOutletEvent } from '@smoovy/router';
import GenericTransition from '../../../transitions/generic';

import prefix from '../prefix';
import scroller from '../../../scroller';
import { parseUrl } from '@smoovy/utils';

function restoreScrollHash() {
  setTimeout(() => {
    const url = parseUrl(window.location.href);
    if (!!url.hash) {
      scroller.scrollToHash(url.hash, true);
    }
  });
}

@customElement(`${prefix}-router-outlet`)
export class RouterOutlet extends LitElement {
  public router?: Router;

  public connectedCallback() {
    super.connectedCallback();

    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    this.router = new Router(window.location.href, {
      outlet: this.tagName.toLowerCase(),
      transitions: [ new GenericTransition() ]
    });

    this.router.outlet?.on(RouterOutletEvent.CONTENT_AFTER_ENTER_END, () => {
      restoreScrollHash();
    });
    restoreScrollHash();
  }

  public disconnectedCallback() {
    super.disconnectedCallback();

    this.router?.destroy();
    delete this.router;
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }
    `;
  }

  public render() {
    return html`<slot></slot>`;
  }
}
