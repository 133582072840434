import {LitElement, html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import { ViewportObserver, ViewportObservable } from '@smoovy/observer';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import prefix from '../prefix';
import styles from './index.scss';
import { QueryDeep } from '../../../utils/query-deep';

gsap.registerPlugin(SplitText);

@customElement(`${prefix}-text-transition`)
export class DjxTextTransition extends LitElement {
  public static styles = styles;
  public done = false;
  public unwrapped = false;
  private textSplit: any;
  private words: HTMLElement[];
  private lines: HTMLElement[];
  private chars: HTMLElement[];
  private viewportObservable: ViewportObservable;

  @property({type: Number})
  private threshold = 0;

  @property({type: String})
  private trigger = 'intersect';

  @property({ type: Number })
  private speed = 1;

  @property({ type: String })
  private selector = '';

  @property({ type: String })
  private mode = '';

  @property({ type: Number })
  private delay = 0;

  private async split(force = false) {
    const { selector, mode } = this;
    return new Promise(resolve => {
      if (force || !this.words || this.words && this.words.length === 0) {
        const targets: any = QueryDeep(this, selector);

        if (targets.length === 0) {
          resolve(false);
          return;
        }
        setTimeout(() => {
          const split = new SplitText(targets, {
            type: mode,
            wordsClass: 'word',
            charsClass: 'char',
            linesClass: 'line',
          });
          this.chars = split.chars as HTMLElement[];
          this.words = split.words as HTMLElement[];
          this.lines = split.lines as HTMLElement[];
          this.textSplit = split;
          resolve(true);
        }, 100);
      } else {
        resolve(true);
      }
    });
  }

  public async connectedCallback() {
    super.connectedCallback();
    this.viewportObservable = ViewportObserver.changed(() => {
      if (this.done) {
        this.unwrap();
      }
    });
  }

  private setTargetsOpacity(opacity: number) {
    const targets: any = QueryDeep(this, this.selector);
    if (targets.length > 0) {
      gsap.to(targets, { duration: 0, opacity });
    }
  }

  private transitionChars() {
    const tl = gsap.timeline({ delay: this.delay });
    tl.add(() => this.setTargetsOpacity(1));
    tl.set(this.chars, { y: 20, opacity: 0 });
    this.chars.forEach((group, index) => {
      tl.to(group, {
        duration: 0.5,
        y: 0,
        opacity: 1,
        ease: 'Power2.easeOut',
      }, index > 0 ? '-=0.425' : 0);
    });
    tl.timeScale(this.speed);
    // tl.add(this.unwrap);
  }

  private transitionLines() {
    const tl = gsap.timeline({ delay: this.delay });
    tl.add(() => this.setTargetsOpacity(1));
    tl.set(this.lines, { y: 20, opacity: 0 });
    this.lines.forEach((group, index) => {
      tl.to(group, {
        duration: 0.9,
        y: 0,
        opacity: 1,
        ease: 'Power2.easeOut',
      }, index > 0 ? '-=0.825' : 0);
    });
    tl.timeScale(this.speed);
    // tl.add(this.unwrap);
  }

  private transitionWords() {
    const tl = gsap.timeline({ delay: this.delay });
    tl.add(() => this.setTargetsOpacity(1));
    tl.set(this.words, { y: 160, opacity: 1 });
    tl.to(this.words, {
      duration: 0.85,
      y: 0,
      stagger: 0.1,
      ease: 'Power3.easeOut',
    });
    tl.timeScale(this.speed);
    // tl.add(this.unwrap);
  }

  private unwrap = () => {
    if (this.textSplit) {
      this.textSplit.revert();
    }
  }

  public reset() {
    this.done = false;
  }

  public transitionIn = async () => {
    if (this.done) return;
    this.done = true;
    const valid = await this.split();
    if (valid) {
      switch(this.mode) {
        case 'lines': this.transitionLines(); break;
        case 'words': this.transitionWords(); break;
        case 'chars': this.transitionChars(); break;
        default: throw new Error('unknown mode');
      }
    }
  }

  updated() {
    if (this.done) return;
    this.setTargetsOpacity(0);
  }

  public render() {
    return html`
      <djx-intersect
        .enabled="${this.trigger === 'intersect'}"
        .threshold="${this.threshold}"
        @enter="${this.transitionIn}"
        @leave="${this.unwrap}"
      >
        <slot></slot>
      </djx-intersect>
    `;
  }
}
